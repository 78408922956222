import dva from 'dva'
import './index.less'

// 1.Initialize
const app = dva()

// 2.Model
app.model(require('./models/user').default);
app.model(require('./models/login').default);
app.model(require('./models/home').default);
app.model(require('./models/gameList').default);
app.model(require('./models/search').default);
app.model(require('./models/company').default);
app.model(require('./models/faq').default);
app.model(require('./models/developer').default);
app.model(require('./models/streamers').default);
app.model(require('./models/streamerClubs').default);

// 3.Router
app.router(require('./router').default)

// 4.Start
app.start('#root')

export default app._store