import React, { Component } from 'react';
import BasicLayout from '../../components/BasicLayout'
import { connect } from 'dva'
import { Card, Col, DatePicker, Row, Spin, Tooltip } from 'antd'
import './styles.less'
import { Space } from 'antd'
import { CaretDownOutlined, CaretUpOutlined, ExclamationCircleOutlined, ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import _ from 'lodash'
import moment from 'moment'
import ReactEcharts from 'echarts-for-react'
import ReactTooltip from 'react-tooltip';
import { routerRedux } from 'dva/router';

import qs from 'query-string';
import Config from '../../config/Config'
import { getToken } from '../../utils/authority'
const { RangePicker } = DatePicker;

function disabledDate(current) {
  let time = moment().add(1, 'days').format('YYYY-MM-DD');
  return current && !moment(current).isBefore(time);
}

class StreamerDetails extends Component {
  constructor(props) {
    super(props)
    let platform=props.topInfo&&props.topInfo.userInfo.platform
    let id = props.match.params.id;
    this.state = {
      id: id,
      platform,
      catalog: [
        {
          nameEN: 'Dimension',
          nameCN: '维度',
          list: [
            {
              nameEN: 'Streamed Hours',
              nameCN: '主播时长',
              value: 'sumLenth',
              icon: require('../../assets/icon/icon2.png'),
              icon_selected: require('../../assets/icon/icon2_selected.png'),
            },
            {
              nameEN: 'Subscribers',
              nameCN: '游戏粉丝数',
              value: 'avgFans',
              icon: require('../../assets/icon/icon3.png'),
              icon_selected: require('../../assets/icon/icon3_selected.png'),
            },
            {
              nameEN: 'Tips',
              nameCN: '游戏打赏收入',
              value: 'sumGift',
              icon: require('../../assets/icon/icon4.png'),
              icon_selected: require('../../assets/icon/icon4_selected.png'),
            },
            {
              nameEN: 'Heat Index',
              nameCN: '游戏热力值',
              value: 'avgHot',
              icon: require('../../assets/icon/icon5.png'),
              icon_selected: require('../../assets/icon/icon5_selected.png'),
            },
            {
              nameEN: 'Niko Index',
              nameCN: 'NIKO指数',
              value: 'sumNiko',
              icon: require('../../assets/common/logo.png'),
              icon_selected: require('../../assets/common/logo.png'),
            },
            // {
            //   nameEN: 'Rocket Gifts',
            //   nameCN: 'Rocket Gifts',
            //   value: 'Rocket Gifts',
            //   // icon: require('../../assets/common/logo.png'),
            //   // icon_selected: require('../../assets/common/logo.png'),
            // },
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            {nameEN: 'Last week', nameCN: '近一周', value: '0'},
            {nameEN: 'Last month', nameCN: '近一月', value: '1'},
            {nameEN: 'Customize', nameCN: '自定义', value: '2'}
          ]
        }
      ],
      catalogSelected: [
        {value: 'sumFans'},
        {value: '0'}
      ],
      date: [],
      search: {
        genID: id,
        cate: 'sumFans',
        startDate: moment().subtract(7,'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
      },
      xAxis: [],  //格式化图表所需数据
      dData: [], //斗鱼
    }
  }

  componentDidMount() {
    const { id, search, catalogSelected, date,  } = this.state
    this.props.dispatch({
      type: 'streamers/apiGetStreamerDetails',
      payload: {genID: id}
    })
    this.fetchClubChart({
      date: date,
      catalogSelected: catalogSelected
    })
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const { id } = this.state
    const { flag, streamerChart } = this.props
    if(flag !== nextProps.flag && nextProps.flag){
      this.props.dispatch({
        type: 'streamers/apiGetStreamerDetails',
        payload: {genID: id}
      })
      this.props.dispatch({
        type: 'streamers/setState',
        payload: {flag: false}
      })
    }
    if(streamerChart!== nextProps.streamerChart && nextProps.streamerChart){
      this.formatData(nextProps.streamerChart, nextProps.locale)
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { catalogSelected, date,id } = this.state
    const { streamerChart } = this.props
    if((catalogSelected !== nextState.catalogSelected && nextState.catalogSelected) || (date!==nextState.date && nextState.date.length!==0)){
      let payload = {}
      payload.startDate = nextState.date[0] ? nextState.date[0] : moment().subtract(7, 'days').format('YYYY-MM-DD')
      payload.endDate = nextState.date[1] ? nextState.date[1] : moment().subtract(1, 'days').format('YYYY-MM-DD')
      payload.cate = nextState.catalogSelected[0].value
      payload.genID = id
      this.setState({ search: payload })
      if(streamerChart !== nextProps.streamerChart && nextProps.streamerChart){
        this.formatData(nextProps.streamerChart, nextProps.locale)
      }
      if(catalogSelected != nextState.catalogSelected && nextState.catalogSelected){
        this.fetchClubChart({
          date: nextState.date,
          catalogSelected: nextState.catalogSelected
        })
      }
    }
  }
    fetchClubChart = (searchParams) => {
      let{id}=this.state
      let payload = {}
      payload.startDate = searchParams.date[0] ? searchParams.date[0] : moment().subtract(7, 'days').format('YYYY-MM-DD')
      payload.endDate = searchParams.date[1] ? searchParams.date[1] : moment().subtract(1, 'days').format('YYYY-MM-DD')
      payload.cate = searchParams.catalogSelected[0].value
      payload.genID =id
      this.props.dispatch({
        type: 'streamers/apiGetStreamerChart',
        payload
      })
    }

  handleFollowed = () => {
    const { id } = this.state
    const { topInfo: {userInfo} } = this.props
    if(userInfo&&userInfo.isLike){
      this.props.dispatch({
        type: 'streamers/apiDeleteFollowed',
        payload: { genID: id }
      })
    }else{
      this.props.dispatch({
        type: 'streamers/apiPostFollowed',
        payload: { genID: id }
      })
    }
  }

  //筛选查询
  handleSelectCatalog = (item, index) => {
    const { catalogSelected, date } = this.state
    // index 0 维度筛选 1 时间筛选
    catalogSelected[index].value = item
    if(index === catalogSelected.length-1){
      if(item && item==='0'){
        date[0] = moment().subtract(7, 'days').format('YYYY-MM-DD')
        date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
      }else if(item && item==='1'){
        date[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
        date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
      }else if(item && item==='2'){
        this.setState({ date: [] })
        return
      }
      this.setState({ date: date.concat() })
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }
  handleChangeDate = (time, dateString) => {
    const { date } = this.state
    if(time){
      date[0] = moment(time[0]).format('YYYY-MM-DD')
      date[1] = moment(time[1]).format('YYYY-MM-DD')
      this.setState({ date: date.concat() })
    }
  }

  //数据图表
  formatData = (datas, locale) => {
    const { search } = this.state
    const { catalogSelected } = this.state
    let xAxis = []
    let dData = []
    let timeFormat = _.groupBy(datas, 'createdAt')
    if(search.cate !== 'sumGift'){
      for (let key in timeFormat){
        xAxis.push(key)
        timeFormat[key].forEach((vo, index)=>{
          dData.push(vo[catalogSelected[0].value])
        })
      }
    }else{
      for (let key in timeFormat){
        xAxis.push(key)
        timeFormat[key].forEach((vo, index)=>{
          dData.push(locale==='EN' ? vo.USsumGift : vo[catalogSelected[0].value])
        })
      }
    }
    this.setState({ xAxis: xAxis.concat(), dData: dData.concat()})
  }
  renderECharts = () => {
    const { xAxis, dData} = this.state
    let{topInfo}=this.props
    const options = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: [ topInfo&&topInfo.userInfo&&topInfo.userInfo.name],
        left: 'center',
        icon: 'roundRect'
      },
      grid: {
        left: '2%',
        right: '2%',
        bottom: '0%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxis,
        axisLabel: {
          interval:0,
          rotate: 40
        },
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: topInfo&&topInfo.userInfo&&topInfo.userInfo.name,
          type: 'line',
          // stack: '总量',
          data: dData
        },
      ]
    }
    return (
      <ReactEcharts
        option={options}
        notMerge={true}
        lazyUpdate={true}
        lazyLoad={true}
        theme="light"
      />
    )
  }
  //导出
  handleExport = () => {
    const { userInfo } = this.props
    const { id } = this.state
    let genID={genID:id}
    if (userInfo && userInfo.status !== 'test' && userInfo.status !== 'off') {
      console.log(`${Config.SERVER_HOME}downloadGifts?WebAuth=${getToken()}&${qs.stringify(genID)}`)
      window.open(`${Config.SERVER_HOME}downloadGifts?WebAuth=${getToken()}&${qs.stringify(genID)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 9 }
      })
    } else {
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }
  render() {
    const customTooltipDataAttrs = (value) => {
      if (!value.date&& !value.count) {
        return null;
      }else if(value.date&& !value.count){
        return {'data-tip': `${value.date} has streamed ${value.gameEn} for ${4} hours`}
      } else{
        return {'data-tip': `${value.date} has streamed ${value.gameEn} for ${value.count} hours`}
      }
    }
      ;
    const { spin, topInfo, streamerGift, locale, streamerChart } = this.props
    const { catalog, catalogSelected } = this.state
    return (
      <BasicLayout selectedKeys={['/streamers']} menu2Name={'Streamers'}>
        <Spin spinning={spin}>
          <div className="details_container">
            {topInfo&&topInfo.userInfo&&(
              <div className="topContent">
                <div className="streamer_icon"><img style={{ cursor: "pointer" }} src={topInfo.userInfo.avatar} onClick={()=>{
                  window.open(`${topInfo.userInfo.roomLink}`)
                }}/></div>
                <div className="streamer_info">
                  <div className="leftTop">
                    <div className="name_room">
                    <div style={{ cursor: "pointer" ,fontSize:20}} 
                      onClick={()=>{
                        window.open(`${topInfo.userInfo.roomLink}`)
                      }}>{topInfo.userInfo.enName}</div>
                      <div style={{ cursor: "pointer" ,fontSize:20}} 
                      onClick={()=>{
                        window.open(`${topInfo.userInfo.roomLink}`)
                      }}>{topInfo.userInfo.name}</div>
                      <div>{topInfo.userInfo.platform}：{topInfo.userInfo.roomID}</div>
                    </div>
                    <div className="btn" onClick={()=>{this.handleFollowed()}}>
                      {topInfo.userInfo.isLike ? <span>Followed streamer</span> : <span>+ My Dashboard</span>}
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="game_info">
                      <span className="title">Games：</span>
                      {topInfo.userInfo.formatGameList&&(
                        topInfo.userInfo.formatGameList.map((vo, index)=>{
                          return <Space key={index} onClick={()=>{this.props.dispatch(routerRedux.push(`/gameList/gameDetails/${vo.id}`))}} key={index} className="gameItem" style={{borderRight: 'none',cursor:'pointer'}}>
                            <div className="icon">
                              <img src={vo&&vo.link}/>
                            </div>
                            <span>{vo&&vo.gameEn}</span>
                          </Space>
                        })
                      )}
                    </div>
                    {topInfo.userInfo.clubInfo&&topInfo.userInfo.clubInfo.name?
                    (<div className="club_info">
                      <span className="title">Clubs：</span>
                      <Space style={{cursor:'pointer'}} onClick={()=>{this.props.dispatch(routerRedux.push(`/streamers/clubDetails/${topInfo.userInfo.clubInfo&&topInfo.userInfo.clubInfo.name}`))}}>
                        <div className="icon">
                          <img src={topInfo.userInfo.clubInfo&&topInfo.userInfo.clubInfo.icon}/>
                        </div>
                        <span className="clubName">{topInfo.userInfo.clubInfo&&topInfo.userInfo.clubInfo.name}</span>
                      </Space>
                    </div>)
                    :null}
                  </div>
                </div>
              </div>
            )}
            {topInfo&&topInfo.data&&(
              <div className="CalendarHeatmapContent">
                <CalendarHeatmap
                  weekdayLabels={['Sun','Mon','Tues','Wed','Thur','Fri','Sat']}
                  height={200}
                  startDate={moment().year(moment().year()).startOf('year').format('YYYY-MM-DD')}
                  endDate={moment().year(moment().year()).endOf('year').format('YYYY-MM-DD')}
                  values={topInfo.data.map((vo)=>{return {date: vo.createdAt, count: vo.duration,game:vo.game,gameEn:vo.gameInfo&&vo.gameInfo.gameEn}})}
                  showWeekdayLabels={true}
                  tooltipDataAttrs={customTooltipDataAttrs}
                  classForValue={(value) => {
                    if (!value) {
                      return 'color-empty';
                    }else if(!value.count){
                      return `color-github-1`;
                    }else if(parseFloat(value.count) <=4){
                      return `color-github-1`;
                    }else if(parseFloat(value.count)<=6&&parseFloat(value.count)>4){
                      return `color-github-1`;
                    }else if(parseFloat(value.count)<=8&&parseFloat(value.count)>6){
                      return `color-github-2`;
                    }else if(parseFloat(value.count)<=16&&parseFloat(value.count)>8){
                      return `color-github-3`;
                    }else{
                      return `color-github-4`;
                    }
                  }}
                />
               <ReactTooltip />
              </div>
            )}
            {topInfo&&topInfo.userInfo&&(
              <div style={{padding: '12px 0'}}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Card title={'Subscribers'} hoverable={true}>
                      <div className="cardValue">
                        <div className="radio"></div>
                        <span>{topInfo.userInfo.Followers}</span>
                      </div>
                      <Space className="changeNum">
                        {topInfo.userInfo.FollowersChange>0?(<CaretUpOutlined style={{color: '#0ae653'}}/>):
                        (<CaretDownOutlined style={{color: '#e02a33'}} />)}
                        <span>{parseInt(topInfo.userInfo.FollowersChange)}</span>
                      </Space>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card title={'Heat Index'} hoverable={true}>
                      <div className="cardValue">
                        <div className="radio"></div>
                        <span>{topInfo.userInfo.Viewers}</span>
                      </div>
                      <Space className="changeNum">
                      {topInfo.userInfo.ViewersChange>0?(<CaretUpOutlined style={{color: '#0ae653'}}/>):
                        (<CaretDownOutlined style={{color: '#e02a33'}} />)}
                        <span>{parseInt(topInfo.userInfo.ViewersChange)}</span>
                      </Space>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card title={'Niko Index'} hoverable={true}>
                      <div className="cardValue">
                        <div className="radio"></div>
                        <span>{(topInfo.userInfo.NikoData)}</span>
                      </div>
                      <Space className="changeNum">
                      {topInfo.userInfo.NikoDataChange>0?(<CaretUpOutlined style={{color: '#0ae653'}}/>):
                        (<CaretDownOutlined style={{color: '#e02a33'}} />)}
                        <span>{parseInt(topInfo.userInfo.NikoDataChange)}</span>
                      </Space>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
            {streamerGift&&streamerGift.giftData&&streamerGift.formatGiftData&&(
              <div className="giftContent">
                <div className="gift_title">
                  <Space className="title">
                  <div className="icon">
                    <img alt="img" src={require('../../assets/icon/icon4_selected.png')} style={{width:20}}/>
                    </div>
                    <span>Large Tips</span>
                  <Tooltip overlayClassName="tooltip" className="text_icon" title="It listed the daily detail of large virtual gifts or tips that have been donated to streamers from per viewers.">
                    <QuestionCircleOutlined />
                  </Tooltip>
                  </Space>
                  <div className="btn" onClick={()=>{this.handleExport()}}>Export history</div>
                </div>
                <Row gutter={[12, 12]}>
                  {streamerGift.formatGiftData.map((vo, index)=>{
                    let totalCount = _.sumBy(vo.list, "count")
                    return <Col span={8} key={index}>
                      <Card
                        hoverable={true}
                        className={"cardBox"}
                        title={
                          <div className="cardTitle">
                            <div className="indexRadio">{index+1}</div>
                            <span className="name">{vo.name}</span>
                          </div>
                        }
                        actions={[
                          <div className="card_foot">
                            Weekly Total：{totalCount}
                          </div>
                        ]}
                      >
                        <div className="cardList_content">
                          {vo.list&&(
                              vo.list.map((item, key)=>{
                                return (
                                  <div className="giftLine" key={key}>
                                    <Col className="lineTxt" style={{color:'#2C67A4'}} span={10}>
                                      {item.name}
                                    </Col>
                                    <Col className="lineTxt" span={12}>
                                      {item.time}
                                    </Col>
                                    <Col className="lineTxt" style={{color:'#2C67A4'}} span={2}>
                                      {item.count}
                                    </Col>
                                  </div>
                                )
                              })
                            )}
                        </div>
                      </Card>
                    </Col>
                  })}
                </Row>
              </div>
            )}
            <div className="divider"></div>
            {streamerChart&&(
              <div className="bottom_card">
                <div className="catalog_line">
                  {catalog[0].list.map((vo, index)=>{
                    return (
                      <div key={index} className="catalog_item hoverDiv" onClick={()=>{this.handleSelectCatalog(vo.value, 0)}}>
                        {vo.icon&&(<img alt="img" className="icon" src={catalogSelected[0].value ? vo.icon_selected : vo.icon}/>)}
                        <span className="title" style={vo.value===catalogSelected[0].value ? {color: '#2C67A4'} : null}>{locale==='EN' ? vo.nameEN : vo.nameCN}</span>
                        {vo.value===catalogSelected[0].value ? <div className="activity_line"></div> : null}
                      </div>
                    )
                  })}
                </div>
                <div className="search_content">
                  <div className="search">
                    <Row>
                      {[catalog[1]].map((vo, index)=>{
                        return (
                          <Col key={index} span={index===0||index===3||index===4||index===5 ? 24 : 12}>
                            <div className="item_line">
                              <div className="title">{locale==='EN' ? vo.nameEN : vo.nameCN}</div>
                              {vo.list.map((item, key)=>{
                                return (
                                  <div key={key}
                                       className={`button ${catalogSelected[1].value===item.value ? 'button_activity' : null}`}
                                       onClick={()=>{this.handleSelectCatalog(item.value, 1)}}
                                  >{locale==='EN' ? item.nameEN : item.nameCN}</div>
                                )
                              })}
                              {
                                catalogSelected[1].value==='2'
                                  ?
                                  <RangePicker
                                    disabledDate={disabledDate}
                                    onChange={this.handleChangeDate}
                                    style={{padding: '2px 14px 2px'}}
                                  />
                                  :
                                  null
                              }
                            </div>
                          </Col>
                        )
                      })}
                    </Row>
                  </div>
                  {/* <div className="export_button" onClick={()=>{}}><ExportOutlined className="icon"/>{locale==='EN' ? 'Export Data' : '导出'}</div> */}
                </div>
                <div>{this.renderECharts()}</div>
              </div>
            )}
          </div>
        </Spin>
      </BasicLayout>
    )
  }
}
export default connect(({ streamers, user })=>({
  topInfo: streamers.topInfo,
  streamerGift: streamers.streamerGift,
  spin: streamers.spin,
  flag: streamers.flag,
  streamerChart: streamers.streamerChart,
  userInfo: user.userInfo,
  locale: user.locale
}))(StreamerDetails)