import {
    getUserInfo,
    putUserInfo,
    postCustomer,
    getCollectionList,
    postDownloadFiles,
    getStreamerList
} from '../services/user'

export default {
    namespace: 'user',
    state: {
        locale: 'EN',
        userInfo: null,
        forbidDownload: false,
        customer: null,
        collectionList: null,
        streamerList: null,
        streamerPage: 1,
        streamerPerPage: 20,
        streamerLoading:false,
        page: 1,
        perPage: 20,
    },
    effects: {
        //获取用户信息
        *apiGetUserInfo({ payload }, { call, put }){
            const response = yield call(getUserInfo, payload)
            yield put({ type: 'setUserInfo', payload: response })
        },
        //用户修改密码
        *apiPutUserInfo({ payload }, { call, put }){
            yield call(putUserInfo, payload)
        },
        //用户信息提交
        *apiPostCustomer({ payload }, { call, put }){
            const response = yield call(postCustomer, payload)
            yield put({ type: 'setState', payload: { customer: response }})
        },
        //获取用户收藏记录
        *apiGetCollectionList({ payload }, { call, put }){
            yield put({ type: 'setState', payload: { streamerLoading:true }})
            const response = yield call(getCollectionList, payload)
            yield put({ type: 'setState', payload: { streamerLoading:false,collectionList: response, page: response.page, perPage: response.perPage }})
        },
        //获取用户主播收藏记录
        *apiGetStreamerList({ payload }, { call, put }){
            const response = yield call(getStreamerList, payload)
            yield put({ type: 'setState', payload: { streamerList: response, streamerPage: response.page, streamerPerPage: response.perPage }})
        },
        //记录用户导出操作
        *apiPostDownloadFiles({ payload }, { call, put }){
            yield call(postDownloadFiles, payload)
        }
    },
    reducers: {
        setUserInfo(state, { payload }){
            let { locale } = state
            // locale = localStorage.getItem('xf1_web_local')
            // if(!locale){
            //     localStorage.setItem('xf1_web_local', 'EN')
            // }
            return {
                ...state,
                userInfo: Object.assign({}, payload),
                // locale: locale,
                locale: 'EN',
            }
        },
        setState(state, { payload }){
            return {
                ...state,
                ...payload
            }
        },
        setLocal(state, { payload }){
            localStorage.setItem('xf1_web_local', payload.local)
            return {
                ...state,
                locale: payload.local,
            }
        }
    }
}