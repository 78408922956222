import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { routerRedux } from 'dva/router'
import { Table } from 'antd'

class Developer extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'developer/apiGetDeveloper'
    })
  }

  renderTable = () => {
    const { list } = this.props
    const columns = [
      {
        title: 'Developers',
        key: 'Developer',
        width: '30%',
        render: (record, row, index) => {
          return (
            <div style={{display: 'flex', flexDirection: 'row',justifyContent: 'flex-start',alignItems: 'center'}}>
              {record.map((vo)=>{
                return (
                  <div
                    key={vo.id}
                    style={{width: '25%', display: 'flex',flexDirection:'row',alignItems:'center',cursor: 'pointer'}}
                    onClick={() => {
                      this.props.dispatch(routerRedux.push(`/developer/details/${vo.id}`))
                    }}
                  >
                    <div style={{width: '150px', height: '70px'}}>
                      {vo.link ? <img src={vo.link} alt={'logo'} style={{width: '100%', height: '100%', objectFit: 'contain'}}/> : null}
                    </div>
                    <div style={{marginLeft: '5px', fontSize: '16px', color: '#333333'}}>{vo.developer}</div>
                  </div>
                )
              })}
            </div>
          )
        }
      }
    ]
    return (
      <Table
        dataSource={list}
        columns={columns}
        pagination={false}
        bordered={true}
        rowKey={(record)=>{return record[0].id}}
      />
    )
  }

  render() {
    return (
      <BasicLayout selectedKeys={['/developer']} menu1Name={'Developers'}>
        <div className="developer">
          <div className="title">Developers</div>
          {this.renderTable()}
        </div>
      </BasicLayout>
    )
  }

}
export default connect(({ developer })=>({
  list: developer.list
}))(Developer)