import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { ExportOutlined, CaretDownOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import ReactEcharts from "echarts-for-react";
import { routerRedux } from 'dva/router'
import { Col, message, Row, DatePicker, Table, Space, Tooltip } from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { getToken } from '../../utils/authority';
import qs from 'query-string';
import Config from '../../config/Config'
import NumericConversion from '../../utils/utils'

const { RangePicker } = DatePicker;

function disabledDate(current) {
  let time = moment().add(1, 'days').format('YYYY-MM-DD');
  return current && !moment(current).isBefore(time);
}

class GameDetails extends Component {
  constructor(props) {
    super(props)
    let id = props.match.params.id;
    this.state = {
      id: id,
      timeValue: null,
      showMore: false,
      catalog: [
        {
          nameEN: 'Dimension',
          nameCN: '维度',
          list: [
            {
              nameEN: 'Streamers',
              nameCN: '主播数量',
              value: 'sumAnchor',
              icon: require('../../assets/icon/icon1.png'),
              icon_selected: require('../../assets/icon/icon1_selected.png'),
            },
            {
              nameEN: 'Streamed Hours',
              nameCN: '主播时长',
              value: 'sumLenth',
              icon: require('../../assets/icon/icon2.png'),
              icon_selected: require('../../assets/icon/icon2_selected.png'),
            },
            {
              nameEN: 'Subscribers',
              nameCN: '游戏粉丝数',
              value: 'avgFans',
              icon: require('../../assets/icon/icon3.png'),
              icon_selected: require('../../assets/icon/icon3_selected.png'),
            },
            {
              nameEN: 'Tips',
              nameCN: '游戏打赏收入',
              value: 'sumGift',
              icon: require('../../assets/icon/icon4.png'),
              icon_selected: require('../../assets/icon/icon4_selected.png'),
            },
            {
              nameEN: 'Heat Index',
              nameCN: '游戏热力值',
              value: 'avgHot',
              icon: require('../../assets/icon/icon5.png'),
              icon_selected: require('../../assets/icon/icon5_selected.png'),
            },
            {
              nameEN: 'Niko Index',
              nameCN: 'NIKO指数',
              value: 'sumNiko',
              icon: require('../../assets/common/logo.png'),
              icon_selected: require('../../assets/common/logo.png'),
            }
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            { nameEN: 'Last week', nameCN: '近一周', value: '0' },
            { nameEN: 'Last month', nameCN: '近一月', value: '1' },
            { nameEN: 'Customize', nameCN: '自定义', value: '2' }
          ]
        }
      ],
      catalogSelected: [
        { value: 'sumAnchor' },
        { value: '0' }
      ],
      date: [],
      gameDate: [],
      search: {
        cate: 'sumAnchor',
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
      },
      xAxis: [],  //格式化图表所需数据
      dData: [], //斗鱼
      hData: [], //虎牙
      bData: [], //bilibili
      platform: [
        { nameEN: 'DouYu', nameCN: '斗鱼', value: 103 },
        { nameEN: 'HuYa', nameCN: '虎牙', value: 102 },
        { nameEN: 'Bilibili', nameCN: '哔哩哔哩', value: 101 },
      ],
      platformID: 103,
      search2: {
        cate: 'avgFans',
      },
    }
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0
    const { id, search2 } = this.state
    this.props.dispatch({
      type: 'gameList/apiGetGameStreamers',
      payload: { gameID: id, ...search2 }
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    const { id, search, platformID } = this.state
    const { userInfo, gameDetails, flag, locale } = this.props
    if (userInfo !== nextProps.userInfo && nextProps.userInfo) {
      this.props.dispatch({
        type: 'gameList/apiGetGameDetails',
        payload: { gameID: parseInt(id), userID: nextProps.userInfo.id, ...search }
      })
    }
    if (gameDetails !== nextProps.gameDetails && nextProps.gameDetails) {
      if (nextProps.gameDetails.datas) {
        this.formatData(nextProps.gameDetails.datas, locale)
      }
      // this.props.dispatch({
      //   type: 'gameList/apiGetTopTenAnchor',
      //   payload: { gameID: parseInt(nextProps.gameDetails.gameID), platformID: platformID }
      // })
    }
    if (flag !== nextProps.flag && nextProps.flag) {
      if (nextProps.flag === 1) {
        message.success('Followed this app!')
      } else {
        message.success('Has unfollowed this app!')
      }
      this.props.dispatch({ type: 'gameList/setState', payload: { flag: false } })
    }
    if (locale !== nextProps.locale && search.cate === 'sumGift') {
      this.formatData(nextProps.gameDetails.datas, nextProps.locale)
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { id, catalogSelected, date, platformID, search2, gameDate } = this.state
    const { userInfo, gameDetails, followedFlag } = this.props
    if ((catalogSelected !== nextState.catalogSelected && nextState.catalogSelected) || (date !== nextState.date && nextState.date.length !== 0)) {
      let payload = {}
      payload.gameID = parseInt(id)
      payload.userID = parseInt(userInfo.id)
      payload.startDate = nextState.date[0] ? nextState.date[0] : moment().subtract(7, 'days').format('YYYY-MM-DD')
      payload.endDate = nextState.date[1] ? nextState.date[1] : moment().subtract(1, 'days').format('YYYY-MM-DD')
      payload.cate = nextState.catalogSelected[0].value
      this.setState({ search: payload })
      this.props.dispatch({
        type: 'gameList/apiGetGameDetails',
        payload
      })
    }
    if (gameDate !== nextState.gameDate && nextState.gameDate) {
      this.props.dispatch({
        type: 'gameList/apiGetGameStreamers',
        payload: { gameID: id, ...nextState.search2, startDate: gameDate[0], endDate: gameDate[1] }
      })
    }
    if ((search2 !== nextState.search2 && nextState.search2) || (followedFlag !== nextProps.followedFlag && nextProps.followedFlag)) {
      this.props.dispatch({
        type: 'gameList/apiGetGameStreamers',
        payload: { gameID: id, ...nextState.search2 }
      })
      this.props.dispatch({
        type: 'gameList/setState',
        payload: { followedFlag: false }
      })
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: 'gameList/setState', payload: { gameDetails: null } })
  }

  //格式化图表数据
  formatData = (datas, locale) => {
    const { search } = this.state
    let xAxis = []
    let dData = []
    let hData = []
    let bData = []
    let timeFormat = _.groupBy(datas, 'date')
    if (search.cate !== 'sumGift') {
      for (let key in timeFormat) {
        if(!_.find(datas,{'date':key,'platform':'bili'})){
          bData.push(0)
        }
        if(!_.find(datas,{'date':key,'platform':'huya'})){
          hData.push(0)
        }
        if(!_.find(datas,{'date':key,'platform':'douyu'})){
          dData.push(0)
        }
        xAxis.push(key)
        timeFormat[key].forEach((vo, index) => {
          if (vo.platform === 'bili') {
            bData.push(vo[search.cate])
          } else if (vo.platform === 'huya') {
            hData.push(vo[search.cate])
          } else if (vo.platform === 'douyu') {
            dData.push(vo[search.cate])
          }
        })
      }
    } else {
      for (let key in timeFormat) {
        if(!_.find(datas,{'date':key,'platform':'bili'})){
          bData.push(0)
        }
        if(!_.find(datas,{'date':key,'platform':'huya'})){
          hData.push(0)
        }
        if(!_.find(datas,{'date':key,'platform':'douyu'})){
          dData.push(0)
        }
        xAxis.push(key)
        timeFormat[key].forEach((vo, index) => {
          if (vo.platform === 'bili') {
            bData.push(locale === 'EN' ? vo.USsumGift : vo[search.cate])
          } else if (vo.platform === 'huya') {
            hData.push(locale === 'EN' ? vo.USsumGift : vo[search.cate])
          } else if (vo.platform === 'douyu') {
            dData.push(locale === 'EN' ? vo.USsumGift : vo[search.cate])
          }
        })
      }
    }
    this.setState({ xAxis: xAxis.concat(), dData: dData.concat(), hData: hData.concat(), bData: bData.concat() })
  }

  //筛选查询
  handleSelectCatalog = (item, index) => {
    const { catalogSelected, date } = this.state
    catalogSelected[index].value = item
    if (index === catalogSelected.length - 1) {
      if (item && item === '0') {
        date[0] = moment().subtract(7, 'days').format('YYYY-MM-DD')
        date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
      } else if (item && item === '1') {
        date[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
        date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
      } else if (item && item === '2') {
        this.setState({ date: [] })
        return
      }
      this.setState({ date: date.concat() })
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }
  handleChangeDate = (time, dateString) => {
    const { date } = this.state
    if (time) {
      date[0] = moment(time[0]).format('YYYY-MM-DD')
      date[1] = moment(time[1]).format('YYYY-MM-DD')
      this.setState({ date: date.concat() })
    }
  }
  handleGameDate = (time, dateString) => {
    const { gameDate } = this.state
    if (time) {
      gameDate[0] = moment(time[0]).format('YYYY-MM-DD')
      gameDate[1] = moment(time[1]).format('YYYY-MM-DD')
      this.setState({ gameDate: gameDate.concat() })
    }
  }
  //关注或取消
  handleCollection = () => {
    const { id } = this.state
    const { gameDetails, userInfo } = this.props
    console.log('gameDetails', gameDetails)
    if (gameDetails.follwed) {
      this.props.dispatch({
        type: 'gameList/apiDeleteCollectionGame',
        payload: { gameID: gameDetails.gameID }
      })
    } else {
      this.props.dispatch({
        type: 'gameList/apiPostCollectionGame',
        payload: { gameID: gameDetails.gameID }
      })
    }
  }

  //数据图表
  renderECharts = () => {
    const { xAxis, dData, hData, bData } = this.state
    const options = {
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['DouYu', 'HuYa', 'Bilibili'],
        left: 'center',
        icon: 'roundRect'
      },
      grid: {
        left: '2%',
        right: '2%',
        bottom: '0%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxis,
        axisLabel: {
          interval: 0,
          rotate: 40
        },
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          name: 'DouYu',
          type: 'line',
          // stack: '总量',
          data: dData
        },
        {
          name: 'HuYa',
          type: 'line',
          // stack: '总量',
          data: hData
        },
        {
          name: 'Bilibili',
          type: 'line',
          // stack: '总量',
          data: bData
        },
      ]
    }
    return (
      <ReactEcharts
        option={options}
        notMerge={true}
        lazyUpdate={true}
        lazyLoad={true}
        theme="light"
      />
    )
  }

  //导出
  handleExport = () => {
    const { userInfo } = this.props
    const { id } = this.state
    let search = {}
    if (userInfo && userInfo.status !== 'test' && userInfo.status !== 'off') {
      search.gameID = parseInt(id)
      window.open(`${Config.SERVER_HOME}downloadStreamer?WebAuth=${getToken()}&${qs.stringify(search)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 7 }
      })
    } else {
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }

  //游戏主播列表
  handleChangeSearch2 = (item, index) => {
    const { search2 } = this.state
    search2.cate = item
    this.setState({ search2: Object.assign({}, search2) })
  }
  renderTable = () => {
    const { id, search2 } = this.state
    const { tableLoading, gameRankList, locale } = this.props
    const pagination = {
      current: parseInt(gameRankList && gameRankList.page) || 0,
      total: (gameRankList || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(gameRankList && gameRankList.perPage) || 0,
      pageSize: gameRankList && gameRankList.perPage,
      showQuickJumper: true,
      position: 'bottom',
    }
    const columns = [
      {
        title: <div className="unCategoryHeader">Rank</div>,
        key: 'id',
        width: '5%',
        render: (record, row, index) => {
          let key = gameRankList && gameRankList.page > 1 ? (gameRankList.page * gameRankList.perPage) - gameRankList.perPage + index + 1 : index + 1
          return (
            <div className="ranking">{key}</div>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Name</div>,
        dataIndex: 'name',
        key: 'name',
        width: '10%',
        render: (text, record, index) => {
          return (
            <Space style={{ cursor: 'pointer' }} onClick={() => { this.props.dispatch(routerRedux.push(`/streamers/streamerDetails/${record.genID}`)) }}>
              <div className="streamerLogo">
                <img src={record.avatar} />
              </div>
              <div>
                <div>{text}</div>
                <div key={index} style={{ fontSize: 12, color: '#F4791F' }}>{record.clubInfo && record.clubInfo.name}</div>
              </div>
            </Space>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Game</div>,
        dataIndex: ['gameInfo', 'gameEn'],
        key: ['gameInfo', 'gameEn'],
        width: '15%',
        render: (text, record, index) => {
          return (
            <div className="game Info" key={index}>
              <Space style={{ cursor: 'pointer' }}>
                {record.gameInfo && (
                  <div className='gamelogo'>
                    <img src={record.gameInfo.link} style={{ width: 40 }} />
                  </div>
                )}
                {text && (<div style={{ display: 'inline-block', width: 120, textAlign: 'center' }}>{text}</div>)}
              </Space>
              <div style={{ textAlign: 'center', fontSize: 12, color: '#F4791F' }}>{record.game}</div>
            </div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch2('avgFans', 0) }} className={`cate_header ${search2.cate === 'avgFans' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="For Games, this measures the daily average number of subscribers that are subscribed to streamers of a game or category during the selected time period. For streamers this measures the total number of subscribers for the streamers channel.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Subscribers' : '游戏粉丝数'}
          </div>
          {gameRankList && gameRankList.amountDatas ? <div className={`total ${search2.cate === 'avgFans' ? 'total_number' : null}`}>{NumericConversion(gameRankList.amountDatas[0].avgFans)}</div> : null}
        </div>,
        width: '10%',
        key: 'avgFans',
        dataIndex: 'avgFans',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search2.cate === 'avgFans' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch2('sumGift', 0) }} className={`cate_header ${search2.cate === 'sumGift' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative value of tips and virtual gifts that streamers receive within the game, category or as an individual streamer. This is measured in RMB and converted to USD.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Tips' : '游戏打赏收入'}
          </div>
          {gameRankList && gameRankList.amountDatas ? <div className={`total ${search2.cate === 'sumGift' ? 'total_number' : null}`}>{locale === 'EN' ? NumericConversion(gameRankList.amountDatas[0].USsumGift) : NumericConversion(gameRankList.amountDatas[0].sumGift)}</div> : null}
        </div>,
        key: 'sumGift',
        dataIndex: locale === 'EN' ? 'USsumGift' : 'sumGift',
        width: '10%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search2.cate === 'sumGift' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch2('avgHot', 0) }} className={`cate_header ${search2.cate === 'avgHot' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="An index based on the daily average value of three streaming platforms’ own algorithms, providing a rating of the games number of viewers, hours streamed, and number of virtual gifts or tips donated.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Heat Index' : '游戏热力值'}
          </div>
          {gameRankList && gameRankList.amountDatas ? <div className={`total ${search2.cate === 'avgHot' ? 'total_number' : null}`}>{NumericConversion(gameRankList.amountDatas[0].avgHot)}</div> : null}
        </div>,
        key: 'avgHot',
        width: '10%',
        dataIndex: 'avgHot',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search2.cate === 'avgHot' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch2('sumNiko', 0) }} className={`cate_header ${search2.cate === 'sumNiko' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="A weighted index estimated by Niko based on an algorithm approximating the number of active viewers of each streamer, game or category. This is a cumulative figure for the period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Niko Index' : 'Niko指数'}
          </div>
          {gameRankList && gameRankList.amountDatas ? <div className={`total ${search2.cate === 'sumNiko' ? 'total_number' : null}`}>{NumericConversion(gameRankList.amountDatas[0].sumNiko)}</div> : null}
        </div>,
        key: 'sumNiko',
        dataIndex: 'sumNiko',
        width: '10%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search2.cate === 'sumNiko' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleChangeSearch2('sumLenth', 0) }} className={`cate_header ${search2.cate === 'sumLenth' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative number of hours streamed for the game, category or individual streamer across the time period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Hours' : '主播时长'}
          </div>
          {gameRankList && gameRankList.amountDatas ? <div className={`total ${search2.cate === 'sumLenth' ? 'total_number' : null}`}>{NumericConversion(gameRankList.amountDatas[0].sumLenth)}</div> : null}
        </div>,
        key: 'sumLenth',
        dataIndex: 'sumLenth',
        width: '8%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search2.cate === 'sumLenth' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={'cate_header'}>Follow</div>,
        key: 'action',
        width: '3%',
        align: 'center',
        fixed: 'right',
        render: (text, record, index) => {
          return (
            <div
              className="followedIcon"
              onClick={() => { this.handleFollowed(record) }}
            >
              <img src={record.isLike ? require('../../assets/common/followed.png') : require('../../assets/common/unFollowed.png')} />
            </div>
          )
        }
      }
    ]
    return (
      <Table
        bordered={true}
        loading={tableLoading}
        dataSource={(gameRankList || {}).data || []}
        columns={columns}
        pagination={pagination}
        rowKey={record => record.id}
        onChange={(pagination, filters, sorter) => {
          this.props.dispatch({
            type: 'gameList/apiGetGameStreamers',
            payload: { page: pagination.current, perPage: pagination.pageSize, gameID: id }
          })
        }}
      />
    )
  }
  handleFollowed = (record) => {
    if (record.isLike) {
      this.props.dispatch({
        type: 'gameList/apiDeleteFollowedStreamers',
        payload: { genID: record.genID }
      })
    } else {
      this.props.dispatch({
        type: 'gameList/apiPostFollowedStreamers',
        payload: { genID: record.genID }
      })
    }
  }
  //十大主播
  renderTopTenAnchor = (data, locale) => {
    return (
      data && data.length !== 0 ? <div className="topTen_anchor">
        <div className="list_item">
          <div className="item"></div>
          <div className="item">{locale === 'EN' ? 'Heat Index' : '热力值'}</div>
          <div className="item opacity">{locale === 'EN' ? 'Niko Index' : '时长'}</div>
          <div className="item opacity">{locale === 'EN' ? 'Tips' : '礼物数'}</div>
        </div>
        {data.map((vo, index) => {
          return (
            <div className="list_item main" key={index}>
              <div className="item">
                <div className={`anchor_icon ${index <= 2 ? `top_three${index}` : null}`}>
                  <img src={vo.avatar} alt="avatar" />
                  {index <= 2 ? <img src={require(`../../assets/common/${index + 1}.png`)} className="rank_icon" alt="icon" /> : null}
                </div>
                <div className="anchor_name">{vo.name}</div>
              </div>
              <div className="item value">{NumericConversion(vo.hot)}</div>
              <div className="item opacity">{NumericConversion(vo.nikoData)}</div>
              <div className="item opacity">{NumericConversion(vo.gift)}</div>
            </div>
          )
        })}
      </div> : null
    )
  }

  render() {
    const { location, gameDetails, locale } = this.props
    const { showMore, catalog, catalogSelected, timeValue, gameDate } = this.state
    return (
      <BasicLayout selectedKeys={['/gameList']} location={location}>
        <div className="details_container">
          <div className="top_card">
            {gameDetails && (<div className="left_logo">
              <img alt="link" src={gameDetails.game && gameDetails.game.link} />
            </div>)}
            {gameDetails && (<div className="right_info">
              <div className="top_line">
                <div className="name_follow">
                  <div className="name">
                    {gameDetails.game ? <span>{locale === 'EN' ? gameDetails.game.gameEn : gameDetails.game.game}</span> : null}
                  </div>
                  <div className="follow hoverDiv" onClick={() => { this.handleCollection() }}>{gameDetails.game && gameDetails.follwed ? (locale === 'EN' ? 'Followed Game' : '已关注') : (locale === 'EN' ? '+ My Dashboard' : '关注')}</div>
                </div>
              </div>
              <div className="text_line">
                <div className={`${showMore ? 'all_txt' : 'txt'}`}>
                  {gameDetails.game && gameDetails.game.des}
                </div>
                {gameDetails.game && gameDetails.game.des ? <div className="more_icon hoverDiv" onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>
                  <span>{locale === 'EN' ? 'More' : '更多'}</span><CaretDownOutlined className={`arrowIcon ${showMore ? 'activity_icon' : null}`} />
                </div> : null}
              </div>
              <div className="other_info">
                <div className="info_item hoverDiv" onClick={() => { this.props.dispatch(routerRedux.push(`/company/companyDetails/${gameDetails.game && gameDetails.game.OperatorInfo && gameDetails.game.OperatorInfo.id}`)) }}>
                  <div>{locale === 'EN' ? 'Publisher' : '出版者'}</div>
                  <div>{gameDetails.game && gameDetails.game.OperatorInfo && gameDetails.game.OperatorInfo.operator}</div>
                </div>
                <div className="info_divider"></div>
                <div className="info_item">
                  <div>{locale === 'EN' ? 'Genre' : '平台'}</div>
                  <div>{gameDetails.game && gameDetails.game.Genre}</div>
                </div>
                <div className="info_divider"></div>
                <div className="info_item">
                  <div>{locale === 'EN' ? 'Platform' : '分类'}</div>
                  <div>{gameDetails.game && gameDetails.game.Platform}</div>
                </div>
                <div className="info_divider"></div>
                <div className="info_item" style={{cursor:'pointer'}} onClick={() => { this.props.dispatch(routerRedux.push(`/company/companyDetails/${gameDetails.game && gameDetails.game.OperatorInfo && gameDetails.game.OperatorInfo.id}`)) }}>
                  <div>{locale === 'EN' ? 'Developer' : '开发商'}</div>
                  <div>{(gameDetails.game && gameDetails.game.DeveloperInfo && gameDetails.game.DeveloperInfo.developer) || 'Not Edit'}</div>
                </div>
              </div>
            </div>)}
          </div>
          <div className="steamer_card">
            <div className="top_search">
              <Row>
                {[{
                  nameEN: 'Time Period',
                  nameCN: '时间段',
                  list: [
                    { nameEN: 'Last week', nameCN: '近一周', value: '0' },
                    { nameEN: 'Last month', nameCN: '近一月', value: '1' },
                    { nameEN: 'Customize', nameCN: '自定义', value: '2' }
                  ]
                }].map((vo, index) => {
                  return (
                    <Col key={index} span={24}>
                      <div className="item_line">
                        <div className="title">{locale === 'EN' ? vo.nameEN : vo.nameCN}</div>
                        <div className="button_content">
                          {vo.list.map((item, key) => {
                            return (
                              <div key={key}
                                className={`button ${timeValue === item.value ? 'button_activity' : null}`}
                                onClick={() => {
                                  this.setState({ timeValue: item.value })
                                  if (item.value == '0') {
                                    gameDate[0] = moment().subtract(8, 'days').format('YYYY-MM-DD')
                                    gameDate[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
                                    this.setState({ gameDate: gameDate.concat() })
                                  } else if (item.value === '1') {
                                    gameDate[0] = moment().subtract(31, 'days').format('YYYY-MM-DD')
                                    gameDate[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
                                    this.setState({ gameDate: gameDate.concat() })
                                  }
                                }}
                              >{locale === 'EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div>
                            )
                          })}
                          {
                            timeValue === '2'
                              ?
                              <RangePicker
                                disabledDate={disabledDate}
                                onChange={this.handleGameDate}
                                style={{ padding: '2px 14px 2px', margin: '0 13px 13px 0' }}
                              />
                              :
                              null
                          }
                        </div>
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
            <div className="export">
              <div className="export_button" onClick={() => { this.handleExport() }}><ExportOutlined className="icon" />{locale === 'EN' ? 'Export Data' : '导出'}</div>
            </div>
            {this.renderTable()}
          </div>
          <div className="bottom_card">
            <div className="catalog_line">
              {catalog[0].list.map((vo, index) => {
                return (
                  <div key={index} className="catalog_item hoverDiv" onClick={() => { this.handleSelectCatalog(vo.value, 0) }}>
                    <img alt="img" className="icon" src={catalogSelected[0].value ? vo.icon_selected : vo.icon} />
                    <span className="title" style={vo.value === catalogSelected[0].value ? { color: '#2C67A4' } : null}>{locale === 'EN' ? vo.nameEN : vo.nameCN}</span>
                    {vo.value === catalogSelected[0].value ? <div className="activity_line"></div> : null}
                  </div>
                )
              })}
            </div>
            <div className="search_content">
              <div className="search">
                <Row>
                  {[catalog[1]].map((vo, index) => {
                    return (
                      <Col key={index} span={index === 0 || index === 3 || index === 4 || index === 5 ? 24 : 12}>
                        <div className="item_line">
                          <div className="title">{locale === 'EN' ? vo.nameEN : vo.nameCN}</div>
                          {vo.list.map((item, key) => {
                            return (
                              <div key={key}
                                className={`button ${catalogSelected[1].value === item.value ? 'button_activity' : null}`}
                                onClick={() => { this.handleSelectCatalog(item.value, 1) }}
                              >{locale === 'EN' ? item.nameEN : item.nameCN}</div>
                            )
                          })}
                          {
                            catalogSelected[1].value === '2'
                              ?
                              <RangePicker
                                disabledDate={disabledDate}
                                onChange={this.handleChangeDate}
                                style={{ padding: '2px 14px 2px' }}
                              />
                              :
                              null
                          }
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
              <div className="export_button" onClick={() => { this.handleExport() }}><ExportOutlined className="icon" />{locale === 'EN' ? 'Export Data' : '导出'}</div>
            </div>
            <div>{this.renderECharts()}</div>
          </div>

          {/*主播排行榜删除*/}
          {/*{gameDetails ? <div className="anchor_card">*/}
          {/*  <div className="title">*/}
          {/*    <p>{locale==='EN' ? `${gameDetails.game.gameEn} Top Streamers` : `${gameDetails.game.game}热门主播`}</p>*/}
          {/*    <p>{locale==='EN' ? 'Top Ten (ranked by Heat Index)' : '前十名（热力值排序）'}</p>*/}
          {/*  </div>*/}
          {/*  <div className="platformHead">*/}
          {/*    <div className="platform">*/}
          {/*      <div>{locale==='EN' ? 'Streaming Platform' : '平台'}</div>*/}
          {/*      {platform.map((vo, index)=>{*/}
          {/*        return (*/}
          {/*          <div className={`button ${platformID===vo.value ? 'button_activity' : null}`}*/}
          {/*               key={index}*/}
          {/*               onClick={()=>{this.setState({ platformID: vo.value })}}*/}
          {/*          >{locale==='EN' ? vo.nameEN : vo.nameCN}</div>*/}
          {/*        )*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*    <div*/}
          {/*      className="moreBtn"*/}
          {/*      onClick={()=>{*/}
          {/*        localStorage.setItem('xf1_web_gameID', this.state.id)*/}
          {/*        this.props.dispatch(routerRedux.push('/streamers'))*/}
          {/*      }}*/}
          {/*    >more</div>*/}
          {/*  </div>*/}
          {/*  {this.renderTopTenAnchor(topTenAnchor, locale)}*/}
          {/*</div> : null}*/}
        </div>
      </BasicLayout>
    )
  }
}

export default connect(({ gameList, user }) => ({
  gameDetails: gameList.gameDetails,
  topTenAnchor: gameList.topTenAnchor,
  flag: gameList.flag,
  tableLoading: gameList.tableLoading,
  gameRankList: gameList.gameRankList,
  followedFlag: gameList.followedFlag,
  userInfo: user.userInfo,
  locale: user.locale
}))(GameDetails)