import React, { Component } from 'react';
import './styles.less';
import { Dropdown, Menu, ConfigProvider, Modal, Row, Col,Form, Button, Input } from 'antd';
import { StarOutlined, LogoutOutlined, RollbackOutlined } from '@ant-design/icons';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';
import enUS from 'antd/es/locale/en_US';
import zhCN from 'antd/es/locale/zh_CN';


const { info } = Modal;
const { SubMenu } = Menu;
const FormItem = Form.Item;
const [form] = Form.useForm();

class BasicLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible:false,
    }
  }
  //------------------------生命周期-----------------------//
  componentDidMount() {
    this.props.dispatch({
      type: 'user/apiGetUserInfo',
    })
  }
  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { locale, forbidDownload } = this.props
    if(forbidDownload !== nextProps.forbidDownload && nextProps.forbidDownload){
      this.forbidDownloadModal = info({
        title: locale==='EN' ? 'Download tips' : '下载提示',
        content: locale==='EN' ? 'Your login account is a test account, please apply for a paid account!' : '你的登录账号为测试账户，请申请付费账户！',
        okText: locale==='EN' ? 'OK' : '知道了',
        onOk: () => {
          this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: false } })
        }
      })
    }
  }
  //------------------------事件-----------------------//
  handleSearch = (value) => {
    if(value){
      this.props.dispatch(routerRedux.push(`/home/searchResult`))
      this.props.dispatch({
        type: 'search/setState',
        payload: {searchParams: value}
      })
    }
  }
  handleJumpToPage = (url) => {
    const { location, userInfo } = this.props
    if((!userInfo || !userInfo.id) && (url === '/gameList' || url === '/company')){
      this.props.dispatch(routerRedux.push('/login'))
      return
    }
    console.log(url,location)
    // if(location&&location.pathname !== url) {
      document.documentElement.scrollTop = 0
      this.props.dispatch(routerRedux.push(url))
    // }
  }
  handleOk = (value) => {
    if(value){
      this.setState({ visible: false })
      this.props.dispatch({
        type: 'user/apiPutUserInfo',
        payload: value
      })
      this.props.dispatch(routerRedux.push('/login'))
      Modal.success({
        content:"Setting Successful"}
        )
    }
  }
  handleCancel = () => {
    this.setState({ visible: false })
  }
  handleClickItem = (e) => {
    const { key } = e
    if(key === 'loginOut'){
      this.props.dispatch({
        type: 'login/loginOut'
      })
      this.props.dispatch({
        type: 'user/setState',
        payload: {userInfo: null}
      })
    }else if(key==='myFocus'){
      this.handleJumpToPage(`/${key}`)
    }else{
      this.setState({ visible: true })
    }
  }
  handleChangeLocal = (key) => {
    this.props.dispatch({
      type: 'user/setLocal',
      payload: {local: key}
    })
  }
  handleOpenPage = (url) => {
    window.location.href = (url)
  }
  //------------------------渲染-----------------------//
  render() {
    const { selectedKeys, menu1Name, menu2Name, userInfo, locale, isFrist } = this.props
    const {visible } = this.state
    const menu = (
      <Menu style={{right:22}} onClick={(item)=>{this.handleChangeLocal(item.key)}}>
        <Menu.Item className="menuItem" key={'CN'}>CN</Menu.Item>
        <Menu.Item className="menuItem" key={'EN'}>EN</Menu.Item>
      </Menu>
    )
    const userItem = (
      <Menu onClick={this.handleClickItem}>
        <Menu.Item key={'myFocus'} className="menuItem"><StarOutlined />My Dashboard</Menu.Item>
        <Menu.Item key={'resetPassword'} className="menuItem"><RollbackOutlined />Reset Password</Menu.Item>
        <Menu.Item key={'loginOut'} className="menuItem"><LogoutOutlined />Sign Out</Menu.Item>
      </Menu>
    )
    return (
      <div>
        <div className="head">
          <div className="main">
            <div className="logo hoverButton" onClick={()=>{this.props.dispatch(routerRedux.push('/home'))}}>
              <img src={require('../../assets/common/logo.png')} alt="logo"/>
            </div>
            <div className="menu">
              <Menu
                mode="horizontal"
                selectedKeys={selectedKeys}
                onClick={({item, key, keyPath})=>{
                  if(key==='about'||key==='search'){
                    console.log('no jump')
                  }else{
                  this.props.dispatch(routerRedux.push(key))
                }
                }}
              >
                <Menu.Item key={'/home'}>{locale==='EN' ? 'Home' : '首页'}</Menu.Item>
                <SubMenu key={'Publisher'} title={<div style={{color: '#333'}}>{menu1Name ? menu1Name : 'Games'}</div>}>
                  <Menu.Item key={'/gameList'}><div style={{color: '#333'}}>{locale==='EN' ? 'Games' : '所有游戏'}</div></Menu.Item>
                  <Menu.Item key={'/company'}><div style={{color: '#333'}}>{locale==='EN' ? 'Publishers' : '所有公司'}</div></Menu.Item>
                  <Menu.Item key={'/developer'}><div style={{color: '#333'}}>Developers</div></Menu.Item>
                </SubMenu>
                <SubMenu key={'Streamers'} title={<div style={{color: '#333'}}>{menu2Name ? menu2Name : 'Streamers'}</div>}>
                  <Menu.Item key={'/streamers'}><div style={{color: '#333'}}>Streamers</div></Menu.Item>
                  <Menu.Item key={'/streamerClubs'}><div style={{color: '#333'}}>Streamer Clubs</div></Menu.Item>
                </SubMenu>
                <Menu.Item key={'/faq'}>{locale==='EN' ? 'FAQ' : '常见问题'}</Menu.Item>
                <Menu.Item key={'/contactUs'}>{locale==='EN' ? 'Contact Us' : '联系我们'}</Menu.Item>
                <Menu.Item key='about'><div  style={{cursor:'pointer',fontSize:18}} className="about niko" onClick={()=>{
                  window.open('https://nikopartners.com/about/')
                }}>{locale==='EN' ? 'About Niko' : '关于Niko'}</div></Menu.Item>
                <Menu.Item key='search' className="searchItem">
                  <div key='search' className='search'>
                  <Input.Search allowClear style={{ width: 220 }} onSearch={(e)=>{ if(e){this.handleSearch(e)}}} placeholder="League of Legends" />
                  </div>
                </Menu.Item>
              </Menu>
            </div>
            <div className="language_login">
              {
                userInfo&&userInfo.id ? <Dropdown overlay={userItem} placement={'bottomRight'}>
                  <div className="user_head hoverButton">
                    {userInfo&&userInfo.username.charAt(0)}
                  </div>
                </Dropdown> : <div className="user_head hoverButton" onClick={()=>{this.props.dispatch(routerRedux.push('/login'))}}>
                  N
                </div>
              }
            </div>
            <Modal
              title="Reset Password"
              visible={visible}
              onOk={async ()=>{
                try {
                  await form.validateFields()
                  const values = form.getFieldsValue()
                  delete values.confirm
                  this.handleOk(values)
                } catch (e) {
        
                }
              }}
              onCancel={this.handleCancel}
            >
              <div className="resetpassword">
              <Form form={form} labelCol={{span: 5}} wrapperCol={{span: 18}}>
                <FormItem
                  label={'password'}
                  name={'password'}
                  rules={[
                    {
                      required: true,
                      message: 'Please Input New Password',
                    }
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    placeholder={'Please Input New Password'}
                    iconRender={(visible)=>{
                      return (
                        <div>
                          {!visible ? <i className="iconfont iconsuozi" style={{color: '#999999'}} /> : <i className="iconfont iconsuo" style={{color: '#999999'}} />}
                        </div>
                      )
                    }}
                  />
                </FormItem>
                <FormItem
                  label={'confirm'}
                  name={'confirm'}
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please Confirm New Password',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                        }
                        return Promise.reject(new Error('Not Same Password'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder={'Please Confirm New Password'}
                    iconRender={(visible)=>{
                      return (
                        <div>
                          {!visible ? <i className="iconfont iconsuozi" style={{color: '#999999'}} /> : <i className="iconfont iconsuo" style={{color: '#999999'}} />}
                        </div>
                      )
                    }}
                  />
                </FormItem>
              </Form>
              </div>
            </Modal>
          </div>
        </div>
        <div className="page_main">
          <ConfigProvider locale={locale==='EN' ? enUS : zhCN}>
            {this.props.children}
          </ConfigProvider>
        </div>
        <div className="bottom_footer">
          <div className="fusion-footer-copyright-area">
            <Row className="content">
              <Col span={12}>
                <div className="copyRight">© Niko Partners 2023</div>
              </Col>
              <Col span={12} style={{textAlign: 'right'}}>
                <a href="https://twitter.com/nikochina" target="_blank" rel="noopener noreferrer">
                  <img className="linkIcon" alt={'twitter'} src={require('../../assets/icon/twitter.png')}/>
                </a>
                <a href="https://www.linkedin.com/company/niko-partners/" target="_blank" rel="noopener noreferrer">
                  <img className="linkIcon" alt={'linkdin'} src={require('../../assets/icon/linkedin.png')}/>
                </a>
              </Col>
            </Row>
          </div>
        </div>
        {isFrist ? <div className="privacy_popup" onClick={(e)=>{e.stopPropagation()}}>
          <div className="text_content">
            <p>We are committed to ensuring the security and protection of the personal information that we process. We have data protection teams committed to ongoing review and vigilance of all matters within the scope of GDPR so that a process of continual assessment, risk management and improvement is embedded in our organization.</p>
            <p>Our GDPR preparations have included a comprehensive review of relevant internal processes, procedures and documentation. Additionally, we have and continue to actively develop and implement data protection policies, procedures, controls and security measures for GDPR compliance.</p>
            <div className="button_ok">
              <Button type={'primary'} onClick={()=>{this.props.dispatch({type: 'login/setState', payload: {isFrist: false}})}}>OK</Button>
            </div>
          </div>
        </div> : null}

      </div>
    )
  }
}
export default connect(({ user, login })=>({
  userInfo: user.userInfo,
  locale: user.locale,
  forbidDownload: user.forbidDownload,
  isFrist: login.isFrist
}))(BasicLayout)