import { getStreamerList, postFollowed, deleteFollowed, getStreamerDetails, getStreamergift, getStreamerChart } from '../services/streamers'
import { message } from 'antd'
import _ from 'lodash'

export default {
  namespace: 'streamers',
  state: {
    loading: false,
    streamerList: null,
    page: 1,
    perPage: 10,
    flag: false,
    topInfo: null,
    streamerGift: null,
    spin: false,
    streamerChart: null
  },
  effects: {
    *apiGetClubList({payload}, {call, put}){
      yield put({ type: 'setState', payload: {loading: true, flag: false} })
      const response = yield call(getStreamerList, payload)
      yield put({ type: 'setState', payload: {loading: false, streamerList: response, page: response.page, perPage: response.perPage} })
    },
    *apiPostFollowed({payload}, {call, put}){
      yield call(postFollowed, payload)
      message.success('Subscribed!')
      yield put({ type: 'setState', payload: {flag: true} })
    },
    *apiDeleteFollowed({payload}, {call, put}){
      yield call(deleteFollowed, payload)
      message.success('Unfollow successfully!')
      yield put({ type: 'setState', payload: {flag: true} })
    },
    *apiGetStreamerDetails({payload}, {call, put}){
      yield put({ type: 'setState', payload: {spin: true}})
      const info = yield call(getStreamerDetails, payload)
      if(info && info.userInfo && info.userInfo.gameList){
        info.userInfo.formatGameList = _.uniqBy(info.userInfo.gameList, 'game')
      }
      const gift = yield call(getStreamergift, payload)
      if(gift && gift.giftData){
        let list = []
        let giftGroup =[]
        if(gift.genID.includes('douyu')){
          giftGroup = _.groupBy(gift.giftData,  function (vo) { return vo.giftInfo.gift; })
        }else{
          giftGroup = _.groupBy(gift.giftData, 'giftName')
        }
        if(giftGroup){
          for (let index in giftGroup) {
            list.push({
              name: (giftGroup[index][0]&&giftGroup[index][0].giftInfo&&giftGroup[index][0].giftInfo.gift) || (giftGroup[index][0]&&giftGroup[index][0].giftName),
              list: giftGroup[index]
            })
          }
          gift.formatGiftData = list
        }
      }
      yield put({ type: 'setState', payload: {spin: false, topInfo: info, streamerGift: gift}})
    },
    *apiGetStreamerChart({payload}, {call, put}){
      let response = yield call(getStreamerChart, payload)
      yield put({ type: 'setState', payload: {streamerChart: response.data}})
    }
  },
  reducers: {
    setState(state, {payload}){
      return {
        ...state,
        ...payload
      }
    }
  }
}