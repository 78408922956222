import { notification } from 'antd';
import { Config } from '../config';
import { getToken } from './authority';
import axios from 'axios';

export default function request(url, options) {
  return new Promise((resolve, reject)=>{
    let {method, body} = options
    // 添加url前缀
    if (!url.indexOf('https://') > -1 || !url.indexOf('http://') > -1) {
      url = Config.SERVER_HOME + (url.indexOf('/')===0?url.substr(1):url)
    }
    let option = {
      method,
      url,
      header: {
        Accept: 'application/json',
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
        Expires: 0,
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
    // TODO 添加token
    let token = getToken()
    if (token) {
      option.headers = option.headers || {};
      option.headers.WebAuth = `Bearer ${token}`;
    }

    // 参数赋值
    switch (method) {
      case 'GET':
      case 'DELETE':
        option.params = body || {}
        break
      case 'POST':
      case 'PATCH':
      case 'PUT':
        option.data = body || {}
        break
      default:
        break
    }
    axios(option).then(response=>{
      resolve(response.data)
    }).catch(error=>{
      if (error.response){
        let {status,data} = error.response;
        if (status === 400){
          let { message } = data
          notification.error({
            message: `请求错误`,
            description: message,
          });
        }
        if (status === 401) {
          window.location.href = '#/login'
          return;
        }
      }else if (error.request){
        throw error
      }else{
        throw error
      }
    })
  })
}

export function requestGet(url, body) {
  return request(url, { method: 'GET', body });
}
export function requestDelete(url, body) {
  return request(url, { method: 'DELETE', body });
}
export function requestPost(url, body) {
  return request(url, { method: 'POST', body });
}
export function requestPatch(url, body) {
  return request(url, { method: 'PATCH', body });
}
export function requestPut(url, body) {
  return request(url, { method: 'PUT', body });
}
