import { getDeveloper, getDeveloperDetails, getDeveloperRankList } from '../services/developer'

export default {
  namespace: 'developer',
  state: {
    list: [],
    details: null,
    detailsList: null,
    page: 1,
    perPage: 20
  },
  effects: {
    *apiGetDeveloper({payload}, {call, put}){
      const response = yield call(getDeveloper, payload)
      let result = []
      for (let i=0,len=response.length;i<len;i+=4){
        result.push(response.slice(i, i+4))
      }
      yield put({ type: 'setState', payload: {list: result} })
    },
    *apiGetDeveloperDetails({payload}, {call, put}){
      const data = yield call(getDeveloperDetails, payload)
      yield put({ type: 'setState', payload: {details: data[0] || null} })
    },
    *apiGetDeveloperRankList({payload}, {call, put}){
      const response = yield call(getDeveloperRankList, payload)
      yield put({ type: 'setState', payload: {detailsList: response} })
    }
  },
  reducers: {
    setState(state, {payload}){
      return {
        ...state,
        ...payload
      }
    }
  }
}