import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { Form, Input } from 'antd'

class PersonalInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      fileList: []
    }
  }

  render() {
    const { location, userInfo } = this.props
    return (
      <BasicLayout location={location}>
        <div className="personal_content">
          <div className="top_title">Personal Information</div>
          <div className="form_content">
            <Form>
              <Form.Item label={'Name'}>
                <Input className="input_line" readOnly={true} value={userInfo&&userInfo.username}/>
              </Form.Item>
              <Form.Item label={'Mail'}>
                <Input className="input_line" readOnly={true} value={userInfo&&userInfo.email}/>
              </Form.Item>
              <Form.Item label={'Phone'}>
                <Input className="input_line" readOnly={true} value={userInfo&&userInfo.phone}/>
              </Form.Item>
              <Form.Item label={'Company'}>
                <Input className="input_line" readOnly={true} value={userInfo&&userInfo.company}/>
              </Form.Item>
            </Form>
          </div>
        </div>
      </BasicLayout>
    )
  }

}

export default connect(({ user })=>({
  userInfo: user.userInfo
}))(PersonalInfo)