import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'

class AboutNiko extends Component {
  constructor(props) {
    super(props)
    this.state = {
      people: [
        {
          icon: require('../../assets/aboutIcon/1.png'),
          name: 'Lisa Cosmas Hanson',
          title: 'Founder and President',
          text: 'Lisa is founder and president of Niko Partners. Her responsibility includes client relations, research & analysis, business development, operations, and partnerships. Lisa founded Niko Partners in 2002 and has since honed her expertise on the Chinese and Southeast Asian online games industries that has benefitted Niko’s clients, companies that are global leaders in game publishing, game services, hardware, and investments. She is based in the US but travels to China regularly.',
        },
        {
          icon: require('../../assets/aboutIcon/2.png'),
          name: 'Xiaofeng Zeng',
          title: 'Vice President',
          text: 'Xiaofeng is Niko’s vice president based in Shanghai. He has worked for Niko for over 10 years. He has more than 15 years of research and analysis experience, expertise in data modeling and data collection, and is bilingual in Mandarin and English. Xiaofeng manages Niko’s China focus groups, database of 2.0 million Chinese consumers, and research collection process. A hardcore gamer, Xiaofeng is the chief liaison with Niko’s China-based technology partners and the primary local contact for our clients based in China.',
        },
        {
          icon: require('../../assets/aboutIcon/3.png'),
          name: 'Daniel Ahmad',
          title: 'Senior Analyst',
          text: 'Daniel Ahmad is an analyst at Niko Partners. He is currently responsible for creating the Value Added News Service (VANA) contributing to the Niko Partners research projects, and writing blog posts and articles for a vast following of readers under the username @ZhugeEX where he tweets about the state of the games industry, as well as for @nikochina. Daniel is often quoted in major news and gaming publications such as Business Insider, VentureBeat, PC Gamer, IGN & Gamespot. Prior to joining Niko Partners, Daniel worked in the mobile industry.',
        },
        {
          icon: require('../../assets/aboutIcon/4.png'),
          name: 'Darang Candra',
          title: 'Director, Southeast Asia',
          text: 'Darang is the Director of Southeast Asia for Niko Partners. He has worked with an array of research institutions including ASEAN Study Center of the University of Indonesia, Daniel K. Inouye Asia-Pacific Center for Security Studies, and the Economist Intelligence Unit. Darang also has two master’s degrees: a Master of International Affairs from University of California, San Diego and a Master of Chinese Studies from Zhejiang University. He is based in Jakarta and is trilingual in English, Bahasa Indonesia, and Chinese.',
        },
        {
          icon: require('../../assets/aboutIcon/5.png'),
          name: 'Alexander Champlin',
          title: 'Senior Analyst, Esports',
          text: 'Alexander received his PhD from the Department of Film and Media Studies at the University of California, Santa Barbara. His academic work focuses video game spectatorship industries, he has published and presented work on esports studios, esports franchising, and examining video game live streaming production cultures. He works as the manager of esports research at Niko Partners, specializing in market trends and insights for Asia.',
        },
        {
          icon: require('../../assets/aboutIcon/6.png'),
          name: 'Tyler Sycamore',
          title: 'Account Manager',
          text: 'Tyler is bilingual in English and Thai, and has worked as a consultant for Thailand-based clients for the past 11 years. Tyler has dual masters degrees in International Business Management Administration and International Affairs, specializing in business and development economics in the APAC region. Previously, Tyler was COO of a technology investment firm and has worked with multi-national clients such as the U.S. government, Lehman Brothers, Coca-Cola, and ING Capital',
        },
        {
          icon: require('../../assets/aboutIcon/7.png'),
          name: 'Jennifer McLean',
          title: 'VP of Industry Relations',
          text: 'Jennifer’s background is launching and growing startup brands across games, mobile, and digital media. The former VP of Marketing at Betcade leading publisher adoption and consumer launch strategy, Jennifer also developed the go to market strategies for Fuse Powered and Gamblit Gaming. Previously Jennifer led marketing for IGN Entertainment and the Game Developers Conference. She has extensive experience managing consumer research, user testing, focus groups and more for both media companies and their brand clients.',
        },
        {
          icon: require('../../assets/aboutIcon/8.png'),
          name: 'Michele Maguire',
          title: 'Advisor',
          text: 'Michele advises on business development and corporate strategy for Niko Partners. She is a global business development executive and former Director of GDC, Gamasutra.com and Game Advertising Online. Michele has 10+ years experience in M+A and international business development. “Niko Partners was my subject matter expert when I needed to understand the game industry in China and remains the most respected source of expert data and advice for companies going to China, and Southeast Asia.”',
        }
      ],
      clients: [
        [
          {name: 'Activision'},
          {name: 'Adobe'},
          {name: 'AIB'},
          {name: 'Incubrands/Alibaba'},
          {name: 'Apple'},
          {name: 'AsiaGroup'},
          {name: 'Blizzard Entertainment'},
          {name: 'Blue Planet Software (Tetris)'},
          {name: 'Boston Consulting Group'},
          {name: 'Chengdu'},
          {name: 'Dreamgame'},
          {name: 'Crytek'},
          {name: 'Digital Sky'},
          {name: 'Disney Interactive'},
          {name: 'Disruptor Beam'}
        ],
        [
          {name: 'e-Club Malaysia'},
          {name: 'Electronic Arts'},
          {name: 'Facebook'},
          {name: 'Garena'},
          {name: 'General Atlantic'},
          {name: 'Giant Interactive'},
          {name: 'Goodgame'},
          {name: 'HP'},
          {name: 'IGT'},
          {name: 'Intel Corporation'},
          {name: 'Jagex'},
          {name: 'Kabam'},
          {name: 'Kingsisle'},
          {name: 'LucasArts (Disney)'},
          {name: 'McKinsey & Company'},
          {name: 'Microsoft Corporation'},
        ],
        [
          {name: 'MOL'},
          {name: 'NCSOFT'},
          {name: 'NPD Group'},
          {name: 'NVIDIA'},
          {name: 'Oculus VR'},
          {name: 'OffGamers'},
          {name: 'PayPal'},
          {name: 'Plantronics'},
          {name: 'PopCap Games (EA)'},
          {name: 'Sega Japan'},
          {name: 'Singapore IDA & MDA'},
          {name: 'Singapore Games Central'},
          {name: 'Smilegate'},
          {name: 'Sony Online Entertainment'},
        ],
        [
          {name: 'T2 Games Asia'},
          {name: 'TCL'},
          {name: 'Tencent'},
          {name: 'THQ'},
          {name: 'Turbine Games'},
          {name: 'Ubisoft'},
          {name: 'Unity'},
          {name: 'UpperDeck'},
          {name: 'U.S. International Trade Commission'},
          {name: 'Vivendi Universal Games'},
          {name: 'Wargaming.net'},
          {name: 'Warner Brothers'},
          {name: 'Yeah Mobi'},
          {name: 'Zynga'},
        ]
      ]
    }
  }

  componentDidMount() {
    const { location } = this.props
    if(location.hash){
      window.scrollTo(0,document.querySelector(location.hash).offsetTop);
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { location } = this.props
    if(location !== nextProps.location && nextProps.location && nextProps.location.hash){
      window.scrollTo(0,document.querySelector(nextProps.location.hash).offsetTop);
    }
  }

  render() {
    const { location, locale } = this.props
    const { people, clients } = this.state
    return (
      <BasicLayout location={location}>
        <div className="about_niko">
          <div className="top_banner">
            <img alt="img" src={require('../../assets/icon/about_niko.png')}/>
            <div className="title">{locale==='EN' ? 'ABOUT NIKO' : '关于NIKO'}</div>
          </div>
          <div className="leader_asign" id="leader">
            <div className="title">The leader in Asian games market intelligence</div>
            <div className="divider"><img alt="img" src={require('../../assets/common/divider.png')}/></div>
            <div className="mini_title">Niko Partners launched in 2002 when founder Lisa Cosmas Hanson built a baseline study on the Chinese games market when no such study existed.</div>
            <div className="description">The Chinese market was a vast unknown and by Niko’s estimates had a value of $100 million, but was growing at a notable pace – and Chinese companies were developing very different games that better matched the interests of a population of 1.3 billion. Today, the market is worth more than $25 billion and Niko Partners has tracked the trends and forecasted the growth every step of the way. Clients clamored for information on Southeast Asia as early as 2010, and now that region is the fastest growing digital games market in the world. In 2017 we introduce research on the market of Taiwan, and we will continue to build our geographic coverage to forge the path for our clients to succeed.</div>
          </div>
          <div className="leadership" id="leadership">
            <div className="shipMain">
              <div className="title">LEADERSHIP</div>
              <div className="divider"><img alt="img" src={require('../../assets/common/divider.png')}/></div>
              <div className="people_content">
                {people.map((vo, index)=>{
                  return (
                    <div key={index} className="people_card">
                      <div className="icon"><img src={vo.icon} alt={'img'}/></div>
                      <div className="name">{vo.name}</div>
                      <div className="title">{vo.title}</div>
                      <div className="text">{vo.text}</div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="clients" id="clients">
            <div className="title">CLIENTS</div>
            <div className="divider"><img alt="img" src={require('../../assets/common/divider.png')}/></div>
            <div className="text">We provide critical strategic information to the world’s leading game publishers and developers, hardware makers, institutional investors, management consulting firms, trade associations and policy makers – all of whom have a vested interest in a thorough understanding of the games industry in China and Southeast Asia.</div>
            <div className="clients_main">
              {[0,1,2,3].map((vo, index)=>{
                return (
                  <div key={index} className="client_card">
                    {clients[vo].map((values, key)=>(
                      <div className="name_line" key={key}>{values.name}</div>
                    ))}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </BasicLayout>
    )
  }

}

export default connect(({ user })=>({
  locale: user.locale
}))(AboutNiko)