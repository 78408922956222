import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { Button } from 'antd'

class Faq extends Component{
  constructor(props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'faq/apiGetFaqList',
      payload: { page: 1, perPage: 50 }
    })
  }

  handleGetNextList = () => {
    const { page, perPage } = this.props
    this.props.dispatch({
      type: 'faq/apiGetNextFaqList',
      payload: { page: page+1, perPage: perPage }
    })
  }

  render() {
    const { faqList, page, lastPage } = this.props
    return (
      <BasicLayout selectedKeys={['/faq']}>
        <div className="faq_page">
          <div className="page_tips">FAQ</div>
          <div>
            {faqList&&faqList.data.map((vo, index)=>{
              return (
                <div key={index} className="item_line">
                  <div className="line_title"><span>Q：</span>{vo.question}</div>
                  <div className="line_text">{vo.asked}</div>
                </div>
              )
            })}
          </div>
          {page!=lastPage ? <Button type={'dashed'} block onClick={()=>{this.handleGetNextList()}}>Next page data</Button> : null}
        </div>
      </BasicLayout>
    )
  }

}
export default connect(({ faq })=>({
  faqList: faq.faqList,
  page: faq.page,
  perPage: faq.perPage,
  lastPage: faq.lastPage,
}))(Faq)