import { getSearchData } from '../services/search'

export default {
  namespace: 'search',
  state: {
    searchList: null,
    page: 1,
    perPage: 10,
    searchParams: null,
  },
  effects: {
    *apiGetSearchList({ payload }, { call, put }){
      const response = yield call(getSearchData, payload)
      yield put({ type: 'setList', payload: response })
    }
  },
  reducers: {
    setList(state, { payload }){
      return {
        ...state,
        searchList: payload,
        page: payload.page,
        perPage: payload.perPage,
      }
    },
    setState(state, {payload}){
      return {
        ...state,
        ...payload
      }
    }
  }
}