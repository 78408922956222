import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { Col, Row, Table, DatePicker, Space, Tooltip } from 'antd'
import NumericConversion from '../../utils/utils'
import { routerRedux } from 'dva/router'
import moment from 'moment'
import { ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { getToken } from '../../utils/authority';
import qs from 'query-string';
import Config from '../../config/Config'

const { RangePicker } = DatePicker;

function disabledDate(current) {
  let time = moment().add(1, 'days').format('YYYY-MM-DD');
  return current && !moment(current).isBefore(time);
}

class MyFocus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      catalog: [
        {
          nameEN: 'Metrics',
          nameCN: '维度',
          list: [
            {nameEN: 'Streamers', nameCN: '主播数量', value: 'sumAnchor'},
            {nameEN: 'Hours Streamed', nameCN: '主播时长', value: 'sumLenth'},
            {nameEN: 'Subscribers', nameCN: '游戏粉丝数', value: 'sumFans'},
            {nameEN: 'Tips', nameCN: '游戏打赏收入', value: 'sumGift'},
            {nameEN: 'Heat Index', nameCN: '游戏热力值', value: 'sumHot'},
            {nameEN: 'Niko Index', nameCN: 'NIKO指数', value: 'sumNiko'}
          ]
        },
        {
          nameEN: 'Streaming Platform',
          nameCN: '流媒体网站',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'DouYu', value: '103'},
            {nameEN: 'HuYa', value: '102'},
            {nameEN: 'Bilibili', value: '101'},
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            {nameEN: 'Day', nameCN: '昨天', value: '0'},
            {nameEN: 'Last week', nameCN: '近一周', value: '1'},
            {nameEN: 'Last month', nameCN: '近一月', value: '2'},
            {nameEN: 'Customize', nameCN: '自定义', value: '3'}
          ]
        }
      ],
      catalogSelected: [
        {value: 'sumAnchor'},
        {value: []},
        {value: '0'}
      ],
      date: [],
      search: {
        cate: 'sumAnchor',
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { userInfo } = this.props
    const { catalogSelected, date, search } = this.state
    if(userInfo !== nextProps.userInfo && nextProps.userInfo){
      this.props.dispatch({
        type: 'user/apiGetCollectionList',
        payload: { page: 1, userID: parseInt(nextProps.userInfo.id), ...search }
      })
      this.props.dispatch({
        type: 'user/apiGetStreamerList',
        payload: { page: 1, userID: parseInt(nextProps.userInfo.id), ...search }
      })
    }
    if((catalogSelected !== nextState.catalogSelected && nextState.catalogSelected) || date!==nextState.date){
      let payload = {}
      payload.startDate = nextState.date[0] ? nextState.date[0] : null
      payload.endDate = nextState.date[1] ? nextState.date[1] : null
      payload.cate = nextState.catalogSelected[0].value
      payload.platformID = nextState.catalogSelected[1].value.length!==0 ? nextState.catalogSelected[1].value.map((vo)=>{return vo}).join(',') : null
      this.setState({ search: payload })
      this.props.dispatch({
        type: 'user/apiGetCollectionList',
        payload: { page: 1, userID: parseInt(nextProps.userInfo.id), ...payload }
      })
      this.props.dispatch({
        type: 'user/apiGetStreamerList',
        payload: { page: 1, userID: parseInt(nextProps.userInfo.id), ...payload }
      })
    }
  }

  handleSelectCatalog = (item, index) => {
    const { catalogSelected, date } = this.state
    if(index === 0 || index === catalogSelected.length-1){
      // if(catalogSelected[index].value === item){
      //   catalogSelected[index].value = null
      //   this.setState({ date: [] })
      //   return
      // }
      catalogSelected[index].value = item
      if(index === catalogSelected.length-1){
        if(item && (item==='0' || item==='3')){
          this.setState({ date: [] })
          return
        }else if(item && item==='1'){
          date[0] = moment().subtract(7, 'days').format('YYYY-MM-DD')
          date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }else if(item && item==='2'){
          date[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
          date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
        this.setState({ date: date.concat() })
      }
    }else{
      let key = catalogSelected[index].value.indexOf(item)
      if(key<0){
        if(!item){
          catalogSelected[index].value = []
        }else{
          catalogSelected[index].value.push(item)
        }
      }else{
        catalogSelected[index].value.splice(key, 1)
      }
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }
  handleChangeDate = (time, dateString) => {
    const { date } = this.state
    if(time){
      date[0] = moment(time[0]).format('YYYY-MM-DD')
      date[1] = moment(time[1]).format('YYYY-MM-DD')
      this.setState({ date: date.concat() })
    }
  }
  renderTable=()=>{
    const { search } = this.state
    const { collectionList, page, perPage, locale } = this.props
    const pagination = {
      current: parseInt(page) || 0,
      total: (collectionList || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(perPage) || 0,
      pageSize: perPage,
      showQuickJumper: true,
      position: 'bottom',
    };
    const columns = [
      {
        title: <div className={'cate_header'}>{locale==='EN' ? 'Rank' : '排名'}</div>,
        key: 'id',
        width: '5%',
        render: (record, row, index) => {
          let key = page > 1 ? (page * perPage) - perPage + index + 1 : index + 1
          return (
            <div className="ranking">{key}</div>
          )
        }
      },
      {
        title: <div className={'cate_header'}>{locale==='EN' ? 'Game' : '游戏'}</div>,
        key: 'gameInfo',
        width: '20%',
        render: (record, row, index) => {
          return (
            <div className="inline" onClick={()=>{this.props.dispatch(routerRedux.push(`/gameList/gameDetails/${record.gameID}`))}}>
              <div className="logo">
                {record.gameInfo && record.gameInfo.link ? <img alt={'img'} src={record.gameInfo.link}/> : null}
              </div>
              {record.gameInfo ? <div className="name">{locale==='EN' ? record.gameInfo.gameEn : record.gameInfo.game}</div> : null}
            </div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumAnchor' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Number of Streamers' : '主播数量'}</div>
          {collectionList&&collectionList.amountDatas ? <div className={search.cate==='sumAnchor' ? 'total_number' : null}>{NumericConversion(collectionList.amountDatas[0].sumAnchor)}</div> : null}
        </div>,
        key: 'sumAnchor',
        dataIndex: 'sumAnchor',
        width: '13%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='sumAnchor' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumLenth' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Streaming Hours' : '主播时长'}</div>
          {collectionList&&collectionList.amountDatas ? <div className={search.cate==='sumLenth' ? 'total_number' : null}>{NumericConversion(collectionList.amountDatas[0].sumLenth)}</div> : null}
        </div>,
        key: 'sumLenth',
        dataIndex: 'sumLenth',
        width: '12.4%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='sumLenth' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumFans' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Subscribers' : '游戏粉丝数'}</div>
          {collectionList&&collectionList.amountDatas ? <div className={search.cate==='sumFans' ? 'total_number' : null}>{NumericConversion(collectionList.amountDatas[0].avgFans)}</div> : null}
        </div>,
        key: 'avgFans',
        dataIndex: 'avgFans',
        width: '12.4%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='sumFans' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumGift' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Amount of Tips' : '游戏打赏收入'}</div>
          {collectionList&&collectionList.amountDatas ? <div className={search.cate==='sumGift' ? 'total_number' : null}>{locale==='EN' ? NumericConversion(collectionList.amountDatas[0].USsumGift) : NumericConversion(collectionList.amountDatas[0].sumGift)}</div> : null}
        </div>,
        key: 'sumGift',
        dataIndex: locale==='EN' ? 'USsumGift' : 'sumGift',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='sumGift' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumHot' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Heat Index' : '游戏热力值'}</div>
          {collectionList&&collectionList.amountDatas ? <div className={search.cate==='sumHot' ? 'total_number' : null}>{NumericConversion(collectionList.amountDatas[0].avgHot)}</div> : null}
        </div>,
        key: 'avgHot',
        dataIndex: 'avgHot',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='sumHot' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumNiko' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Niko Index' : 'Niko指数'}</div>
          {collectionList&&collectionList.amountDatas ? <div className={search.cate==='sumNiko' ? 'total_number' : null}>{NumericConversion(collectionList.amountDatas[0].sumNiko)}</div> : null}
        </div>,
        key: 'sumNiko',
        dataIndex: 'sumNiko',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='sumNiko' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      }
    ]
    return (
      <Table
        dataSource={(collectionList || {}).data || []}
        columns={columns}
        pagination={pagination}
        rowKey={record => record.gameID}
        bordered={true}
        onChange={this.handleTableChange}
      />
    )
  }
  renderStreamerTable=()=>{
    const { search } = this.state
    const { streamerList, streamerPage, streamerPerPage, locale,streamerLoading } = this.props
    const pagination = {
      current: parseInt(streamerPage) || 0,
      total: (streamerList || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(streamerPerPage) || 0,
      pageSize: streamerPerPage,
      showQuickJumper: true,
      position: 'bottom',
    };
    const columns = [
      {
        title: <div className="unCategoryHeader">Rank</div>,
        key: 'id',
        width: '3%',
        render: (record, row, index) => {
          let key = streamerPage > 1 ? (streamerPage * streamerPerPage) - streamerPerPage + index + 1 : index + 1
          return (
            <div className="ranking">{key}</div>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Name</div>,
        dataIndex: 'name',
        key: 'name',
        width: '15%',
        render: (text, record, index) => {
          return (
            <Space style={{ cursor: 'pointer' }} onClick={() => { this.props.dispatch(routerRedux.push(`/streamers/streamerDetails/${record.genID}`)) }}>
              <div className="streamerLogo">
                <img src={record.avatar} />
              </div>
              <div>
                <div>{record.enName}</div>
                <div>{text}</div>
                <div key={index} style={{ fontSize: 12, color: '#F4791F' }}>{(record.clubInfo && record.clubInfo.name) || record.official}</div>
              </div>
            </Space>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Game</div>,
        dataIndex: ['gameInfo', 'gameEn'],
        key: ['gameInfo', 'gameEn'],
        width: '15%',
        render: (text, record, index) => {
          return (
            <div className="game Info" key={index}>
              <Space style={{ cursor: 'pointer' }} onClick={() => { this.setState({ gameID: record.gameInfo.id }) }} >
                {record.gameInfo && (
                  <div className='gamelogo'>
                    <img src={record.gameInfo.link} style={{ width: 40 }} />
                  </div>
                )}
                {text && (<div style={{ display: 'inline-block', width: 120, textAlign: 'center' }}>{text}</div>)}
              </Space>
              <div style={{ textAlign: 'center', fontSize: 12, color: '#F4791F' }}>{record.game}</div>
            </div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('avgFans', 0) }} className={`cate_header ${search.cate === 'avgFans' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="For Games, this measures the daily average number of subscribers that are subscribed to streamers of a game or category during the selected time period. For streamers this measures the total number of subscribers for the streamers channel.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Subscribers' : '游戏粉丝数'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'avgFans' ? 'total_number' : null}`}>{NumericConversion(streamerList.amountDatas[0].avgFans)}</div> : null}
        </div>,
        width: '8%',
        key: 'avgFans',
        dataIndex: 'avgFans',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate === 'avgFans' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('sumGift', 0) }} className={`cate_header ${search.cate === 'sumGift' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative value of tips and virtual gifts that streamers receive within the game, category or as an individual streamer. This is measured in RMB and converted to USD.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Tips' : '游戏打赏收入'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'sumGift' ? 'total_number' : null}`}>{locale === 'EN' ? NumericConversion(streamerList.amountDatas[0].USsumGift) : NumericConversion(streamerList.amountDatas[0].sumGift)}</div> : null}
        </div>,
        key: 'sumGift',
        dataIndex: locale === 'EN' ? 'USsumGift' : 'sumGift',
        width: '8%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumGift' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('sumHot', 0) }} className={`cate_header ${search.cate === 'sumHot' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="An index based on the daily average value of three streaming platforms’ own algorithms, providing a rating of the games number of viewers, hours streamed, and number of virtual gifts or tips donated.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Heat Index' : '游戏热力值'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'sumHot' ? 'total_number' : null}`}>{NumericConversion(streamerList.amountDatas[0].avgHot)}</div> : null}
        </div>,
        key: 'avgHot',
        width: '8%',
        dataIndex: 'avgHot',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumHot' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('sumNiko', 0) }} className={`cate_header ${search.cate === 'sumNiko' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="A weighted index estimated by Niko based on an algorithm approximating the number of active viewers of each streamer, game or category. This is a cumulative figure for the period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Niko Index' : 'Niko指数'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'sumNiko' ? 'total_number' : null}`}>{NumericConversion(streamerList.amountDatas[0].sumNiko)}</div> : null}
        </div>,
        key: 'sumNiko',
        dataIndex: 'sumNiko',
        width: '8%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumNiko' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={() => { this.handleSelectCatalog('sumLenth', 0) }} className={`cate_header ${search.cate === 'sumLenth' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative number of hours streamed for the game, category or individual streamer across the time period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale === 'EN' ? 'Hours' : '主播时长'}
          </div>
          {streamerList && streamerList.amountDatas ? <div className={`total ${search.cate === 'sumLenth' ? 'total_number' : null}`}>{NumericConversion(streamerList.amountDatas[0].sumLenth)}</div> : null}
        </div>,
        key: 'sumLenth',
        dataIndex: 'sumLenth',
        width: '6%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate === 'sumLenth' ? { color: '#F4791F' } : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={'cate_header'}>Follow</div>,
        key: 'action',
        width: '3%',
        align: 'center',
        fixed: 'right',
        render: (text, record, index) => {
          return (
            <div
              className="followedIcon"
              onClick={() => { this.handleFollowed(record) }}
            >
              <img src={record.isLike ? require('../../assets/common/followed.png') : require('../../assets/common/unFollowed.png')} />
            </div>
          )
        }
      }
    ]
    return (
      <Table
        dataSource={(streamerList || {}).data || []}
        columns={columns}
        pagination={pagination}
        loading={streamerLoading}
        rowKey={record => record.id}
        bordered={true}
        onChange={this.handlestreamerTableChange}
      />
    )
  }
  handleTableChange = (pagination, filters, sorter) => {
    const { userInfo } = this.props
    const { search } = this.state
    this.props.dispatch({
      type: 'user/apiGetCollectionList',
      payload: { page: pagination.current, perPage: pagination.pageSize, userID: parseInt(userInfo.id), ...search }
    })
  }
  handlestreamerTableChange = (pagination, filters, sorter) => {
    const { userInfo } = this.props
    const { search } = this.state
    this.props.dispatch({
      type: 'user/apiGetStreamerList',
      payload: { page: pagination.current, perPage: pagination.pageSize, userID: parseInt(userInfo.id), ...search }
    })
  }
  handleExport = () => {
    const { search } = this.state
    const { userInfo, page } = this.props
    if(userInfo && userInfo.status!=='test' && userInfo.status!=='off'){
      search.page = page
      search.userID = parseInt(userInfo.id)
      window.open(`${Config.SERVER_HOME}downloadStar?WebAuth=${getToken()}&${qs.stringify(search)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 5 }
      })
    }else{
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }
  handleStreamersExport = () => {
    const { search } = this.state
    const { userInfo, page } = this.props
    if(userInfo && userInfo.status!=='test' && userInfo.status!=='off'){
      search.page = page
      search.userID = parseInt(userInfo.id)
      window.open(`${Config.SERVER_HOME}downloadStarStreamers?WebAuth=${getToken()}&${qs.stringify(search)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 7 }
      })
    }else{
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }
  render(){
    const { location, locale } = this.props
    const { catalog, catalogSelected } = this.state
    return (
      <BasicLayout location={location}>
        <div className="focus_content">
          <div className="top_search">
            <Row>
              {catalog.map((vo, index)=>{
                return (
                  <Col key={index} span={24}>
                    <div className="item_line">
                      <div className="title">{locale==='EN' ? vo.nameEN : vo.nameCN}</div>
                      {vo.list.map((item, key)=>{
                        return (
                          <div key={key}
                               className={`button ${index===0 || index===2 ? (catalogSelected[index].value===item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value)>=0||(!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null) }`}
                               onClick={()=>{this.handleSelectCatalog(item.value, index)}}
                          >{locale==='EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div>
                        )
                      })}
                      {
                        index===catalog.length-1&&catalogSelected[index].value==='3'
                          ?
                          <RangePicker
                            disabledDate={disabledDate}
                            onChange={this.handleChangeDate}
                            style={{padding: '2px 14px 2px'}}
                          />
                          :
                          null
                      }
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
          <div style={{fontSize:20,fontWeight:'bold',marginTop:20}}>Games:</div>
          <div className="export">
            <div className="export_button" onClick={()=>{this.handleExport()}}><ExportOutlined className="icon"/>{locale==='EN' ? 'Export Data' : '导出'}</div>
          </div>
          {this.renderTable()}
        </div>
        <div className="steamer_card">
          <div style={{fontSize:20,fontWeight:'bold'}}>Streamers:</div>
          <div className="export">
            <div className="export_button" onClick={()=>{this.handleStreamersExport()}}><ExportOutlined className="icon"/>{locale==='EN' ? 'Export Data' : '导出'}</div>
          </div>
          {this.renderStreamerTable()}
        </div>
      </BasicLayout>
    )
  }

}

export default connect(({ user })=>({
  userInfo: user.userInfo,
  collectionList: user.collectionList,
  streamerList: user.streamerList,
  streamerPage: user.streamerPage,
  streamerPerPage: user.streamerPerPage,
  page: user.page,
  perPage: user.perPage,
  locale: user.locale
}))(MyFocus)
