import { requestGet, requestPost } from '../utils/requestApi';

export async function getStreamerList(payload){
  return requestGet('streamer', payload)
}

export async function postFollowed(payload) {
  return requestPost('favorite', payload)
}
export async function deleteFollowed(payload) {
  return requestPost('unfavorite', payload)
}

export async function getStreamerDetails(payload) {
  return requestGet('streamerdetail', payload)
}
export async function getStreamergift(payload) {
  return requestGet('streamergift', payload)
}
export async function getStreamerChart(payload) {
  return requestGet('streamerchart', payload)
}