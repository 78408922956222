import { format } from 'd3-format'

// 数字转换为K形式表示
export default function NumericConversion (number, type) {
  let text = null
  if(type){
    return (number/1000).toFixed(2)  + 'k'
  }
  if(number > 1000){
    text = format('.2s')(number).replace('k','K').replace('G', 'B')
  }else{
    text = number&&parseInt(number)
  }
  return text
}