import React, { Component } from 'react'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { connect } from 'dva'
import { Col, DatePicker, Row, Space, Table, Tooltip } from 'antd'
import { ExportOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import NumericConversion from '../../utils/utils'
import { routerRedux } from 'dva/router'
import moment from 'moment'

import qs from 'query-string';
import Config from '../../config/Config'
import { getToken } from '../../utils/authority'
const { RangePicker } = DatePicker

function disabledDate(current) {
  let time = moment().add(1, 'days').format('YYYY-MM-DD');
  return current && !moment(current).isBefore(time);
}

class StreamerClubs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      catalog: [
        {
          nameEN: 'Metrics',
          nameCN: '维度',
          list: [
            {nameEN: 'Subscribers', nameCN: '游戏粉丝数', value: 'avgFans'},
            {nameEN: 'Streamers', nameCN: '主播数量', value: 'sumAnchor'},
            {nameEN: 'Tips', nameCN: '游戏打赏收入', value: 'sumGift'},
            {nameEN: 'Heat Index', nameCN: '游戏热力值', value: 'avgHot'},
            {nameEN: 'Niko Index', nameCN: 'NIKO指数', value: 'sumNiko'},
            {nameEN: 'Hours Streamed', nameCN: '主播时长', value: 'sumLenth'}
          ]
        },
        {
          nameEN: 'Streaming Platform',
          nameCN: '流媒体网站',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'DouYu', value: '103'},
            {nameEN: 'HuYa', value: '102'},
            {nameEN: 'Bilibili', value: '101'},
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            {nameEN: 'Day', nameCN: '昨天', value: '0'},
            {nameEN: 'Last week', nameCN: '近一周', value: '1'},
            {nameEN: 'Last month', nameCN: '近一月', value: '2'},
            {nameEN: 'Customize', nameCN: '自定义', value: '3'}
          ]
        }
      ],
      catalogSelected: [
        {value: 'avgFans'},
        {value: []},
        {value: '0'}
      ],
      date: [],
      search: {
        cate: 'avgFans',
      },
      showMore: false,
    }
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'streamerClubs/apiGetStreamerClubs',
      payload: {page: 1, perPage: 20}
    })
  }
  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { perPage } = this.props
    const { catalogSelected, date } = this.state
    if((catalogSelected !== nextState.catalogSelected && nextState.catalogSelected) || (date!==nextState.date)){
      let payload = {}
      payload.startDate = nextState.date[0] ? nextState.date[0] : null
      payload.endDate = nextState.date[1] ? nextState.date[1] : null
      payload.cate = nextState.catalogSelected[0].value
      payload.platformID = nextState.catalogSelected[1].value.length!==0 ? nextState.catalogSelected[1].value.map((vo)=>{return vo}).join(',') : null
      this.setState({ search: payload })
      this.props.dispatch({
        type: 'streamerClubs/apiGetStreamerClubs',
        payload: { page: 1, perPage: perPage, ...payload }
      })
    }
  }

  handleSelectCatalog = (item, index) => {
    const { catalogSelected, date } = this.state
    if(index === 0 || index === catalogSelected.length-1){
      if(catalogSelected[index].value === item){
        catalogSelected[index].value = null
        this.setState({ date: [] })
        return
      }
      catalogSelected[index].value = item
      if(index === catalogSelected.length-1){
        if(item && (item==='0' || item==='3')){
          this.setState({ date: [] })
          return
        }else if(item && item==='1'){
          date[0] = moment().subtract(7, 'days').format('YYYY-MM-DD')
          date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }else if(item && item==='2'){
          date[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
          date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
        this.setState({ date: date.concat() })
      }
    }else{
      let key = catalogSelected[index].value.indexOf(item)
      if(key<0){
        if(!item){
          catalogSelected[index].value = []
        }else{
          catalogSelected[index].value.push(item)
        }
      }else{
        catalogSelected[index].value.splice(key, 1)
      }
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }
    //导出
    handleExport = () => {
      const { userInfo } = this.props
      const { id, search } = this.state
      if (userInfo && userInfo.status !== 'test' && userInfo.status !== 'off') {
        console.log(`${Config.SERVER_HOME}downloadClubs?WebAuth=${getToken()}&${qs.stringify(search)}`)
        window.open(`${Config.SERVER_HOME}downloadClubs?WebAuth=${getToken()}&${qs.stringify(search)}`)
        this.props.dispatch({
          type: 'user/apiPostDownloadFiles',
          payload: { userID: parseInt(userInfo.id), fileID: 8 }
        })
      } else {
        this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
      }
    }
  
  handleReset = () => {
    this.setState({
      catalogSelected: [
        {value: 'avgFans'},
        {value: []},
        {value: '0'}
      ],
      date: [],
      search: {
        cate: 'avgFans',
      },
      showMore: false,
    })
  }
  handleChangeDate = (time, dateString) => {
    const { date } = this.state
    if(time){
      date[0] = moment(time[0]).format('YYYY-MM-DD')
      date[1] = moment(time[1]).format('YYYY-MM-DD')
      this.setState({ date: date.concat() })
    }
  }
  renderTable = () => {
    const { search } = this.state
    const {loading, clubList, page, perPage, locale} = this.props
    const pagination = {
      current: parseInt(page) || 0,
      total: (clubList || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(perPage) || 0,
      pageSize: perPage,
      showQuickJumper: true,
      position: 'bottom',
    }
    const columns = [
      {
        title: <div className="unCategoryHeader">Rank</div>,
        key: 'id',
        width: '5%',
        render: (record, row, index) => {
          let key = page > 1 ? (page * perPage) - perPage + index + 1 : index + 1
          return (
            <div className="ranking">{key}</div>
          )
        }
      },
      {
        title: <div className="unCategoryHeader">Streamer Clubs</div>,
        dataIndex: 'club',
        key: 'club',
        width: '18%',
        render: (text, record, index) => {
          return (
            <Space style={{cursor: 'pointer'}} onClick={()=>{this.props.dispatch(routerRedux.push(`/streamers/clubDetails/${record.club}`))}}>
              <div className="streamerLogo">
                <img src={record.clubInfo&&record.clubInfo.icon} />
              </div>
              <div key={index}>
              <div>{record.enName}</div>
              <div>{text}</div>
              </div>
            </Space>
          )
        }
      },
      {
        title: <div onClick={()=>{this.handleSelectCatalog('avgFans', 0)}} className={`cate_header ${search.cate==='avgFans' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="For Games, this measures the daily average number of subscribers that are subscribed to streamers of a game or category during the selected time period. For streamers this measures the total number of subscribers for the streamers channel.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale==='EN' ? 'Subscribers' : '游戏粉丝数'}
          </div>
          {clubList&&clubList.amountDatas ? <div className={`total ${search.cate==='avgFans' ? 'total_number' : null}`}>{NumericConversion(clubList.amountDatas[0].avgFans)}</div> : null}
        </div>,
        key: 'avgFans',
        dataIndex: 'avgFans',
        width: '10%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='avgFans' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div id={'header'} onClick={()=>{this.handleSelectCatalog('sumAnchor', 0)}} className={`cate_header ${search.cate==='sumAnchor' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the total cumulative number of streamers for the game or category on a daily cumulative basis for the time period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale==='EN' ? 'Streamers' : '主播数量'}
          </div>
          {clubList&&clubList.amountDatas ? <div className={`total ${search.cate==='sumAnchor' ? 'total_number' : null}`}>
            {NumericConversion(clubList.amountDatas[0].sumAnchor)}
          </div> : null}
        </div>,
        key: 'sumAnchor',
        dataIndex: 'sumAnchor',
        width: '12%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='sumAnchor' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={()=>{this.handleSelectCatalog('sumGift', 0)}} className={`cate_header ${search.cate==='sumGift' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative value of tips and virtual gifts that streamers receive within the game, category or as an individual streamer. This is measured in RMB and converted to USD.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale==='EN' ? 'Tips' : '游戏打赏收入'}
          </div>
          {clubList&&clubList.amountDatas ? <div className={`total ${search.cate==='sumGift' ? 'total_number' : null}`}>{locale==='EN' ? NumericConversion(clubList.amountDatas[0].USsumGift) : NumericConversion(clubList.amountDatas[0].sumGift)}</div> : null}
        </div>,
        key: 'sumGift',
        dataIndex: locale==='EN' ? 'USsumGift' : 'sumGift',
        width: '12%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='sumGift' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={()=>{this.handleSelectCatalog('avgHot', 0)}} className={`cate_header ${search.cate==='avgHot' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="An index based on the daily average value of three streaming platforms’ own algorithms, providing a rating of the games number of viewers, hours streamed, and number of virtual gifts or tips donated.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale==='EN' ? 'Heat Index' : '游戏热力值'}
          </div>
          {clubList&&clubList.amountDatas ? <div className={`total ${search.cate==='avgHot' ? 'total_number' : null}`}>{NumericConversion(clubList.amountDatas[0].avgHot)}</div> : null}
        </div>,
        key: 'avgHot',
        dataIndex: 'avgHot',
        width: '12%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='avgHot' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={()=>{this.handleSelectCatalog('sumNiko', 0)}} className={`cate_header ${search.cate==='sumNiko' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="A weighted index estimated by Niko based on an algorithm approximating the number of active viewers of each streamer, game or category. This is a cumulative figure for the period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale==='EN' ? 'Niko Index' : 'Niko指数'}
          </div>
          {clubList&&clubList.amountDatas ? <div className={`total ${search.cate==='sumNiko' ? 'total_number' : null}`}>{NumericConversion(clubList.amountDatas[0].sumNiko)}</div> : null}
        </div>,
        key: 'sumNiko',
        dataIndex: 'sumNiko',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='sumNiko' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div onClick={()=>{this.handleSelectCatalog('sumLenth', 0)}} className={`cate_header ${search.cate==='sumLenth' ? 'cate_selected' : null}`}>
          <div className="title_name">
            <Tooltip overlayClassName="tooltip" className="text_icon" title="This measures the cumulative number of hours streamed for the game, category or individual streamer across the time period selected.">
              <QuestionCircleOutlined />
            </Tooltip>
            {locale==='EN' ? 'Streamed Hours' : '主播时长'}
          </div>
          {clubList&&clubList.amountDatas ? <div className={`total ${search.cate==='sumLenth' ? 'total_number' : null}`}>{NumericConversion(clubList.amountDatas[0].sumLenth)}</div> : null}
        </div>,
        key: 'sumLenth',
        dataIndex: 'sumLenth',
        width: '10%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='sumLenth' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      }
    ]
    return (
      <Table
        bordered={true}
        loading={loading}
        dataSource={(clubList || {}).data || []}
        columns={columns}
        pagination={pagination}
        rowKey={record => record.id}
        onChange={(pagination, filters, sorter)=>{
          this.props.dispatch({
            type: 'streamerClubs/apiGetStreamerClubs',
            payload: {page: pagination.current, perPage: pagination.pageSize, ...search}
          })
        }}
      />
    )
  }

  render() {
    const { locale } = this.props
    const { catalog, catalogSelected, showMore } = this.state
    return (
      <BasicLayout selectedKeys={['/streamerClubs']} menu2Name={'Streamer Clubs'}>
        <div className="streamersContain">
          <div className="top_search">
            <Row>
              {catalog.map((vo, index)=>{
                return (
                  <Col key={index} span={24}>
                    <div className="item_line">
                      <div className="title">{locale==='EN' ? vo.nameEN : vo.nameCN}</div>
                      <div className="button_content">
                        {vo.list.map((item, key)=>{
                          if(!showMore && key<7){
                            return (
                              <div key={key}
                                   className={`button ${index===0 || index===2 || index===catalogSelected.length-1 ? (catalogSelected[index].value===item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value)>=0||(!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null) }`}
                                   onClick={()=>{this.handleSelectCatalog(item.value, index)}}
                              >{locale==='EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div>
                            )
                          }else{
                            return (
                              showMore ? <div key={key}
                                              className={`button ${index===0 || index===2 || index===catalogSelected.length-1 ? (catalogSelected[index].value===item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value)>=0||(!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null) }`}
                                              onClick={()=>{this.handleSelectCatalog(item.value, index)}}
                              >{locale==='EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div> : null
                            )
                          }
                        })}
                        {vo.list.length===23 ? <div className={'button more'}>
                          <span onClick={()=>{this.setState({ showMore: !this.state.showMore })}}>
                            {!showMore ? (locale==='EN' ? 'Open' : '展开') : (locale==='EN' ? 'Take back' : '收起')}
                          </span>
                        </div> : null}
                        {
                          index===catalog.length-1&&catalogSelected[index].value==='3'
                            ?
                            <RangePicker
                              disabledDate={disabledDate}
                              onChange={this.handleChangeDate}
                              style={{padding: '2px 14px 2px', margin: '0 13px 13px 0'}}
                            />
                            :
                            null
                        }
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
          <div className="export">
            <div className="export_button" onClick={()=>{this.handleReset()}}>{locale==='EN' ? 'Reset' : '重置'}</div>
            <div className="export_button" onClick={()=>{ this.handleExport()}}><ExportOutlined className="icon"/>{locale==='EN' ? 'Export Data' : '导出'}</div>
          </div>
          {this.renderTable()}
        </div>
      </BasicLayout>
    )
  }

}
export default connect(({ streamerClubs, user })=>({
  loading: streamerClubs.loading,
  clubList: streamerClubs.clubList,
  page: streamerClubs.page,
  perPage: streamerClubs.perPage,
  userInfo: user.userInfo,
  locale: user.locale
}))(StreamerClubs)
