import {
  postUserLogin
} from '../services/login'
import { setToken } from '../utils/authority'
import { routerRedux } from 'dva/router'

export default {
  namespace: 'login',
  state: {
    loginStatus: false,
    isFrist: false,
  },
  effects: {
    *apiPostUserLogin({ payload }, { call, put }){
      const response = yield call(postUserLogin, payload)
      yield put({ type: 'changeLoginStatus', payload: response })
      if(response.token && response.user){
        yield put(routerRedux.replace('/'))
        if(response.isFrist){
          yield put({type: 'setState', payload: {isFrist: true}})
        }
      }
    },
    *loginOut({ payload }, { call, put }){
      yield put({
        type: 'changeLoginStatus',
        payload: {
          status: false,
          token: ''
        }
      })
      yield put(routerRedux.replace('/login'))
    }
  },
  reducers: {
    changeLoginStatus(state, { payload }){
      setToken(payload.token);
      return {
        ...state,
        loginStatus: payload.status
      }
    },
    setState(state, {payload}){
      return {
        ...state,
        ...payload
      }
    }
  }
}