import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { Input, Button, Form, Row, Col, message } from 'antd'

const { TextArea } = Input
const FormItem = Form.Item
class ContactUs extends Component {
  formRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      username: null,
      email: null,
      company: null,
      desc: null,
      phone: null,
    }
  }

  //-------------------------生命周期----------------------------//
  componentWillReceiveProps(nextProps, nextContext) {
    const { customer } = this.props
    if(customer !== nextProps.customer && nextProps.customer){
      message.success('Submitted successfully!')
      this.formRef.current.resetFields()
      this.props.dispatch({ type: 'user/setState', payload: {customer: null} })
    }
  }

  //-------------------------事件----------------------------//
  onFinish = (payload) => {
    this.props.dispatch({
      type: 'user/apiPostCustomer',
      payload
    })
  }
  handleResetForm = () => {
    this.formRef.current.resetFields()
  }
  //-------------------------渲染----------------------------//
  render() {
    const { userInfo, locale } = this.props
    return (
      <BasicLayout selectedKeys={['/contactUs']}>
        <div className="contact_container">
          <div className="contact_us">
            <img className="img" src={require('../../assets/us/top.png')} alt="top"/>
            <div className="title">{locale==='EN' ? 'CONTACT US' : '联系我们'}</div>
          </div>
          <div className="contact_middle">
            <div className="title">Niko</div>
            <div className="divider"><img alt="img" src={require('../../assets/common/divider.png')}/></div>
            <div className="text">
              We would love to hear from you! Please fill out the contact form and one of our team will be in touch.
            </div>
          </div>
          <div className="contact_map">
            <div className="info_card">
              <div className="info_line">
                <img alt="icon" src={require('../../assets/icon/position.png')}/>
                <span>SILICON VALLEY 1999 S. Bascom Ave, Suite 700 Campbell, CA 95008 USA</span>
              </div>
              <div className="info_line"><img alt="icon" src={require('../../assets/icon/phoneNumber.png')}/><span>+1 408.354.0888</span></div>
              <div className="info_line"><img alt="icon" src={require('../../assets/icon/email.png')}/><span>sales@nikopartners.com</span></div>
            </div>
          </div>
          <div className="contact_got">
            <div className="main">
              <div className="title">{locale==='EN' ? 'Contact information' : '联系信息'}</div>
              <div className="divider"><img alt="img" src={require('../../assets/common/divider.png')}/></div>
              <Form ref={this.formRef} onFinish={this.onFinish}>
                <Row gutter={24}>
                  <Col span={12}>
                    <FormItem name={'username'} rules={[{required: true, message: locale==='EN' ? 'Please enter name' : '请输入姓名'}]}>
                      <Input placeholder={locale==='EN' ? 'name' : '姓名'} className="inline_input"/>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name={'email'} rules={[{required: true, message: locale==='EN' ? 'Please enter email' : '请输入邮箱地址'}]}>
                      <Input placeholder={locale==='EN' ? 'mailbox' : '邮箱'} className="inline_input"/>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name={'phone'} rules={[{required: true, message: locale==='EN' ? 'Please enter phone' : '请输入手机号码'}]}>
                      <Input placeholder={locale==='EN' ? 'cell-phone number' : '手机号码'} className="inline_input"/>
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <FormItem name={'company'} rules={[{required: true, message: locale==='EN' ? 'Please enter company' : '请输入公司'}]}>
                      <Input placeholder={locale==='EN' ? 'company' : '公司'} className="inline_input"/>
                    </FormItem>
                  </Col>
                  <Col span={24}>
                    <FormItem name={'desc'} rules={[{required: true, message: locale==='EN' ? 'Enter your information or ideas here' : '在这里输入您的信息或想法'}]}>
                      <TextArea
                        rows={6}
                        placeholder={locale==='EN' ? 'Enter the message here' : '在这里输入您的信息或想法'}
                      />
                    </FormItem>
                  </Col>
                  <Col span={12}>
                    <div className="reset"><span onClick={()=>{this.handleResetForm()}}>{locale==='EN' ? 'Fill in again' : '重新填写'}</span></div>
                  </Col>
                  <Col span={12}>
                    <FormItem style={{ textAlign: 'right' }}>
                      <Button htmlType={'submit'} disabled={userInfo&&userInfo.id ? false : true}>{locale==='EN' ? 'Submit Form' : '发送给我们'}</Button>
                    </FormItem>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </BasicLayout>
    )
  }

}
export default connect(({ user })=>({
  customer: user.customer,
  userInfo: user.userInfo,
  locale: user.locale
}))(ContactUs)