import { requestGet, requestPost, requestDelete } from '../utils/requestApi'

export async function getRankList(payload){
  return requestGet('rankList', payload)
}

export async function getGameDetails(payload){
  return requestGet('gameInfo', payload)
}

export async function postCollectionGame(payload){
  return requestPost('favorite', payload)
}

export async function deleteCollectionGame(payload){
  return requestDelete(`favorite/1`, payload)
}

export async function getTopTenAnchor(payload){
  return requestGet('anchors', payload)
}