import React, { Component } from 'react'
import { connect } from 'dva'
import BasicLayout from '../../components/BasicLayout'
import './styles.less'
import { Col, Input, Row, Table, DatePicker, Tag } from 'antd'
import { ExportOutlined, SearchOutlined } from '@ant-design/icons'
import moment from 'moment'
import NumericConversion from '../../utils/utils'
import { routerRedux } from 'dva/router'
import { getToken } from '../../utils/authority';
import qs from 'query-string';
import Config from '../../config/Config'

const { RangePicker } = DatePicker;

function disabledDate(current) {
  let time = moment().add(1, 'days').format('YYYY-MM-DD');
  return current && !moment(current).isBefore(time);
}

class SearchResult extends Component {
  constructor(props) {
    super(props)
    // let searchParams = this.props.match.params.value
    this.state = {
      // searchParams: searchParams,
      catalog: [
        {
          nameEN: 'Metrics',
          nameCN: '维度',
          list: [
            {nameEN: 'Streamers', nameCN: '主播数量', value: 'sumAnchor'},
            {nameEN: 'Hours Streamed', nameCN: '主播时长', value: 'sumLenth'},
            {nameEN: 'Subscribers', nameCN: '游戏粉丝数', value: 'sumFans'},
            {nameEN: 'Tips', nameCN: '游戏打赏收入', value: 'sumGift'},
            {nameEN: 'Heat Index', nameCN: '游戏热力值', value: 'sumHot'},
            {nameEN: 'Niko Index', nameCN: 'NIKO指数', value: 'sumNiko'}
          ]
        },
        {
          nameEN: 'Streaming Platform',
          nameCN: '流媒体网站',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'DouYu', value: '103'},
            {nameEN: 'HuYa', value: '102'},
            {nameEN: 'Bilibili', value: '101'},
          ]
        },
        {
          nameEN: 'Game Publisher',
          nameCN: '经营者',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'Tencent', value: 'Tencent'},
            {nameEN: 'NetEase', value: 'Netease'},
            {nameEN: 'Steam', value: 'Steam'},
            {nameEN: 'Other', value: 'other'},
          ]
        },
        {
          nameEN: 'Game Segment',
          nameCN: '平台',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'PC', value: 'PC'},
            {nameEN: 'Mobile', value: 'Mobile'},
            {nameEN: 'Console', value: 'Console'},
            // {nameEN: 'Non-game', value: 'Non-game'}
          ]
        },
        {
          nameEN: 'Genre',
          nameCN: '类型',
          list: [
            {nameEN: 'All', value: null},
            {nameEN: 'MOBA', value: 'MOBA'},
            {nameEN: 'Battle Royale', value: 'Battle Royale'},
            {nameEN: 'RPG', value: 'RPG'},
            // {nameEN: 'Non-game', value: 'Non-game'},
            {nameEN: 'FPS/TPS', value: 'FPS/TPS'},
            {nameEN: 'Auto Chess', value: 'Auto Chess'},
            // {nameEN: 'Series games', value: 'Series games'},
            {nameEN: 'Racing', value: 'Racing'},
            {nameEN: 'Sandbox', value: 'Sandbox'},
            {nameEN: 'Trading Card', value: 'Trading Card'},
            {nameEN: 'Strategy', value: 'Strategy'},
            {nameEN: 'RTS', value: 'RTS'},
            {nameEN: 'Action', value: 'Action'},
            {nameEN: 'Sporting', value: 'Sporting'},
            {nameEN: 'Horror', value: 'Horror game'},
            {nameEN: 'Puzzle', value: 'Puzzle'},
            {nameEN: 'Simulation', value: 'Simulation'},
            {nameEN: 'Adventure', value: 'Adventure'},
            {nameEN: 'Poker & Mahjong', value: 'Poker & Mahjong'},
            {nameEN: 'Running', value: 'Running'},
            {nameEN: 'Casual', value: 'Casual'},
            {nameEN: 'Idle', value: 'Idle'},
            {nameEN: 'Dancing', value: 'Dancing'},
            {nameEN: 'Roguelike', value: 'Roguelike'},
          ]
        },
        {
          nameEN: 'Time Period',
          nameCN: '时间段',
          list: [
            {nameEN: 'Day', nameCN: '昨天', value: '0'},
            {nameEN: 'Last week', nameCN: '近一周', value: '1'},
            {nameEN: 'Last month', nameCN: '近一月', value: '2'},
            {nameEN: 'Customize', nameCN: '自定义', value: '3'}
          ]
        }
      ],
      catalogSelected: [
        {value: 'sumAnchor'},
        {value: []},
        {value: null},
        {value: []},
        {value: []},
        {value: '0'}
      ],
      date: [],
      search: {
        cate: 'sumAnchor',
      },
      showMore: false,
    }
  }

  componentDidMount() {
    const { search } = this.state
    const { searchParams } = this.props
    this.props.dispatch({
      type: 'search/apiGetSearchList',
      payload: { page: 1, keyword: searchParams, ...search }
    })
  }

  UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
    const { catalogSelected, date } = this.state
    const { searchParams } = this.props
    if((catalogSelected !== nextState.catalogSelected && nextState.catalogSelected) || (date!==nextState.date) || (searchParams !== nextProps.searchParams)){
      let payload = {}
      payload.startDate = nextState.date[0] ? nextState.date[0] : null
      payload.endDate = nextState.date[1] ? nextState.date[1] : null
      payload.cate = nextState.catalogSelected[0].value
      payload.platformID = nextState.catalogSelected[1].value.length!==0 ? nextState.catalogSelected[1].value.map((vo)=>{return vo}).join(',') : null
      payload.Operator = nextState.catalogSelected[2].value ? nextState.catalogSelected[2].value : null
      payload.Platform = nextState.catalogSelected[3].value.length!==0 ? nextState.catalogSelected[3].value.map((vo)=>{return vo}).join(',') : null
      payload.gameGenre = nextState.catalogSelected[4].value.length!==0 ? nextState.catalogSelected[4].value.map((vo)=>{return vo}).join(',') : null
      this.setState({ search: payload })
      this.props.dispatch({
        type: 'search/apiGetSearchList',
        payload: { page: 1, keyword: nextProps.searchParams, ...payload }
      })
    }
  }

  handleSelectCatalog = (item, index) => {
    const { catalogSelected, date } = this.state
    if(index === 0 || index === 2 || index === catalogSelected.length-1){
      if(index===2 && item==='other'){
        this.props.dispatch(routerRedux.push('/company'))
        return
      }
      if(catalogSelected[index].value === item){
        catalogSelected[index].value = null
        this.setState({ date: [] })
        return
      }
      catalogSelected[index].value = item
      if(index === catalogSelected.length-1){
        if(item && (item==='0' || item==='3')){
          this.setState({ date: [] })
          return
        }else if(item && item==='1'){
          date[0] = moment().subtract(7, 'days').format('YYYY-MM-DD')
          date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }else if(item && item==='2'){
          date[0] = moment().subtract(30, 'days').format('YYYY-MM-DD')
          date[1] = moment().subtract(1, 'days').format('YYYY-MM-DD')
        }
        this.setState({ date: date.concat() })
      }
    }else{
      let key = catalogSelected[index].value.indexOf(item)
      if(key<0){
        if(!item){
          catalogSelected[index].value = []
        }else{
          catalogSelected[index].value.push(item)
        }
      }else{
        catalogSelected[index].value.splice(key, 1)
      }
    }
    this.setState({ catalogSelected: catalogSelected.concat() })
  }
  handleChangeDate = (time, dateString) => {
    const { date } = this.state
    if(time){
      date[0] = moment(time[0]).format('YYYY-MM-DD')
      date[1] = moment(time[1]).format('YYYY-MM-DD')
      this.setState({ date: date.concat() })
    }
  }

  handleSearchData = (value) => {
    this.props.dispatch({
      type: 'search/setState',
      payload: {searchParams: value}
    })
  }
  handleJumpToDetails = (id) => {
    if(id){
      this.props.dispatch(routerRedux.push(`/gameList/gameDetails/${id}`))
    }
  }
  renderTable = () => {
    const { search } = this.state
    const { searchList, page, perPage, locale } = this.props
    const pagination = {
      current: parseInt(page) || 0,
      total: (searchList || {}).total || 0,
      defaultCurrent: 1,
      defaultPageSize: parseInt(perPage) || 0,
      pageSize: perPage,
      pageSizeOptions: ['10', '20', '30'],
      showQuickJumper: true,
      position: 'bottom',
    }
    const columns = [
      {
        title: <div className={'cate_header'}>{locale==='EN' ? 'Rank' : '排名'}</div>,
        key: 'id',
        width: '5%',
        render: (record, row, index) => {
          let key = page > 1 ? (page * perPage) - perPage + index + 1 : index + 1
          return (
            <div className="ranking">
              {key<=3 ? <img src={require(`../../assets/icon/top${key}.png`)} alt={'ranking'}/> : key}
            </div>
          )
        }
      },
      {
        title: <div className={'cate_header'}>{locale==='EN' ? 'Game' : '游戏'}</div>,
        key: 'gameInfo',
        width: '22%',
        render: (record, row, index) => {
          return (
            <div className="inline" onClick={()=>{this.handleJumpToDetails(record.gameID)}}>
              <div className="logo">
                {record.gameInfo && record.gameInfo.link ? <img alt={'img'} src={record.gameInfo.link}/> : null}
              </div>
              {record.gameInfo ? <div className="name">
                <div className='game name'>{locale==='EN' ? record.gameInfo.gameEn : record.gameInfo.game}</div> 
                <Tag style={{backgroundColor:'#F4A460'}} className='comp'>{record.gameInfo.Operator} </Tag>
              </div> : null}
            </div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumAnchor' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Streamers' : '主播数量'}</div>
          {searchList&&searchList.amountDatas ? <div className={search.cate==='sumAnchor' ? 'total_number' : null}>{NumericConversion(searchList.amountDatas[0].sumAnchor)}</div> : null}
        </div>,
        key: 'sumAnchor',
        dataIndex: 'sumAnchor',
        width: '10%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='sumAnchor' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumLenth' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Streaming Hours' : '主播时长'}</div>
          {searchList&&searchList.amountDatas ? <div className={search.cate==='sumLenth' ? 'total_number' : null}>{NumericConversion(searchList.amountDatas[0].sumLenth)}</div> : null}
        </div>,
        key: 'sumLenth',
        dataIndex: 'sumLenth',
        width: '12.4%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='sumLenth' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumFans' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Subscribers' : '游戏粉丝数'}</div>
          {searchList&&searchList.amountDatas ? <div className={search.cate==='sumFans' ? 'total_number' : null}>{NumericConversion(searchList.amountDatas[0].avgFans)}</div> : null}
        </div>,
        key: 'avgFans',
        dataIndex: 'avgFans',
        width: '12.4%',
        align: 'right',
        render: (record, row, index) => {
          return (
            <div className="custom_txt" style={search.cate==='sumFans' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumGift' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Amount of Tips' : '游戏打赏收入'}</div>
          {searchList&&searchList.amountDatas ? <div className={search.cate==='sumGift' ? 'total_number' : null}>{locale==='EN' ? NumericConversion(searchList.amountDatas[0].USsumGift) : NumericConversion(searchList.amountDatas[0].sumGift)}</div> : null}
        </div>,
        key: 'sumGift',
        dataIndex: locale==='EN' ? 'USsumGift' : 'sumGift',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='sumGift' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumHot' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Heat Index' : '游戏热力值'}</div>
          {searchList&&searchList.amountDatas ? <div className={search.cate==='sumHot' ? 'total_number' : null}>{NumericConversion(searchList.amountDatas[0].avgHot)}</div> : null}
        </div>,
        key: 'avgHot',
        dataIndex: 'avgHot',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='sumHot' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      },
      {
        title: <div className={`cate_header ${search.cate==='sumNiko' ? 'cate_selected' : null}`}>
          <div>{locale==='EN' ? 'Niko Index' : 'Niko指数'}</div>
          {searchList&&searchList.amountDatas ? <div className={search.cate==='sumNiko' ? 'total_number' : null}>{NumericConversion(searchList.amountDatas[0].sumNiko)}</div> : null}
        </div>,
        key: 'sumNiko',
        dataIndex: 'sumNiko',
        width: '12.4%',
        align: 'right',
        render: (record, text) => {
          return (
            <div className="custom_txt" style={search.cate==='sumNiko' ? {color: '#F4791F'} : null}>{NumericConversion(record)}</div>
          )
        }
      }
    ]
    return (
      <Table
        dataSource={(searchList || {}).data || []}
        columns={columns}
        pagination={pagination}
        rowKey={(record, index) => {return record.gameID}}
        bordered={true}
        onChange={this.handleTableChange}
      />
    )
  }
  handleTableChange = (pagination, filters, sorter) => {
    const {  searchParams } = this.props
    const {  search } = this.state
    this.props.dispatch({
      type: 'search/apiGetSearchList',
      payload: { page: pagination.current, perPage: pagination.pageSize, keyword: searchParams, ...search }
    })
  }

  handleExport = () => {
    const { page, userInfo } = this.props
    const { search, searchParams } = this.state
    if(userInfo && userInfo.status!=='test' && userInfo.status!=='off'){
      search.page = page
      search.keyword = searchParams
      for (let index in search){
        if(!search[index]){
          delete search[index]
        }
      }
      window.open(`${Config.SERVER_HOME}downloadSearch?WebAuth=${getToken()}&${qs.stringify(search)}`)
      this.props.dispatch({
        type: 'user/apiPostDownloadFiles',
        payload: { userID: parseInt(userInfo.id), fileID: 1 }
      })
    }else{
      this.props.dispatch({ type: 'user/setState', payload: { forbidDownload: true } })
    }
  }

  render() {
    const { location, locale, searchParams } = this.props
    const { catalog, catalogSelected, showMore } = this.state
    return (
      <BasicLayout location={location}>
        <div className="resultPage">
          <div className="banner_search">
            <div className="search">
              <div className="title">{locale==='EN' ? 'Real-Time Game Ranking' : '实时游戏排名'}</div>
              {/* <div className="searchInput">
                <Input className="input"
                       placeholder={locale==='EN' ? "Search for the game name you want" : "搜索您想要的游戏名称"}
                       defaultValue={searchParams}
                       onPressEnter={(e)=>{this.handleSearchData(e.target.value)}}
                />
                <div className="search_icon"><SearchOutlined /></div>
              </div> */}
            </div>
          </div>
          <div className="result_main">
            <div className="main_content">
              {searchParams ? <div className="search_name">
                ”{searchParams}“ {locale==='EN' ? 'search results' : '搜索结果'}
              </div> : null}
              <div className="top_search">
                <Row>
                  {catalog.map((vo, index)=>{
                    return (
                      <Col key={index} span={24}>
                        <div className="item_line">
                          <div className="title">{locale==='EN' ? vo.nameEN : vo.nameCN}</div>
                          <div className="button_content">
                            {vo.list.map((item, key)=>{
                              if(!showMore && key<7){
                                return (
                                  <div key={key}
                                       className={`button ${index===0 || index===2 || index===catalogSelected.length-1 ? (catalogSelected[index].value===item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value)>=0||(!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null) }`}
                                       onClick={()=>{this.handleSelectCatalog(item.value, index)}}
                                  >{locale==='EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div>
                                )
                              }else{
                                return (
                                  showMore ? <div key={key}
                                                  className={`button ${index===0 || index===2 || index===catalogSelected.length-1 ? (catalogSelected[index].value===item.value ? 'button_activity' : null) : (catalogSelected[index].value.indexOf(item.value)>=0||(!catalogSelected[index].value[0] && !item.value) ? 'button_activity' : null) }`}
                                                  onClick={()=>{this.handleSelectCatalog(item.value, index)}}
                                  >{locale==='EN' ? item.nameEN : (item.nameCN ? item.nameCN : item.nameEN)}</div> : null
                                )
                              }
                            })}
                            {vo.list.length===23 ? <div className={'button more'}>
                          <span onClick={()=>{this.setState({ showMore: !this.state.showMore })}}>
                            {!showMore ? (locale==='EN' ? 'Open' : '展开') : (locale==='EN' ? 'Take back' : '收起')}
                          </span>
                            </div> : null}
                            {
                              index===catalog.length-1&&catalogSelected[index].value==='3'
                                ?
                                <RangePicker
                                  disabledDate={disabledDate}
                                  onChange={this.handleChangeDate}
                                  style={{padding: '2px 14px 2px', margin: '0 13px 13px 0'}}
                                />
                                :
                                null
                            }
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </Row>
              </div>
              <div className="export_line">
                <div className="export_button" onClick={()=>{this.handleExport()}}><ExportOutlined className="icon"/>{locale==='EN' ? 'Export Data' : '导出'}</div>
              </div>
              {this.renderTable()}
            </div>
          </div>
        </div>
      </BasicLayout>
    )
  }
}

export default connect(({ search, user })=>({
  searchList: search.searchList,
  page: search.page,
  perPage: search.perPage,
  searchParams: search.searchParams,
  userInfo: user.userInfo,
  locale: user.locale
}))(SearchResult)