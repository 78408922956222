import {
  getCompanyList,
  getCompanyGame
} from '../services/company'

export default {
  namespace: 'company',
  state: {
    list: [],
    companyGame: null,
    page: 1,
    perPage: 20,
    companyDetails: null,
  },
  effects: {
    *apiGetCompanyList({ payload }, { call, put }){
      const response = yield call(getCompanyList, payload)
      let result = []
      for (let i=0,len=response.length;i<len;i+=4){
        result.push(response.slice(i, i+4))
      }
      yield put({type: 'setState', payload: { list: result }})
    },
    *apiGetCompanyDetails({ payload }, { call, put }){
      const response = yield call(getCompanyList, payload)
      yield put({type: 'setState', payload: { companyDetails: response[0] || null }})
    },
    *apiGetCompanyGame({ payload }, { call, put }){
      const response = yield call(getCompanyGame, payload)
      yield put({type: 'setState', payload: { companyGame: response, page: response.page, perPage: response.perPage }})
    }
  },
  reducers: {
    setState(state, { payload }){
      return {
        ...state,
        ...payload
      }
    }
  }
}