import { requestGet } from '../utils/requestApi'

export async function getDeveloper(payload) {
  return requestGet('developer', payload)
}

export async function getDeveloperDetails (payload) {
  return requestGet('developer', payload)
}
export async function getDeveloperRankList (payload) {
  return requestGet('rankList', payload)
}