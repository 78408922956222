import {
  getCateList
} from '../services/home'
import _ from 'lodash'


export default {
  namespace: 'home',
  state: {
    cateList: [
      {nameEN: 'Streamers', nameCN: '主播数量', cate: 'sumAnchor', tips: 'This measures the total cumulative number of streamers for the game or category on a daily cumulative basis for the time period selected.', list: []},
      {nameEN: 'Streamed Hours', nameCN: '主播时长', cate: 'sumLenth', tips: 'This measures the cumulative number of hours streamed for the game, category or individual streamer across the time period selected.', list: []},
      {nameEN: 'Tips', nameCN: '游戏打赏收入', cate: 'sumGift', tips: 'This measures the cumulative value of tips and virtual gifts that streamers receive within the game, category or as an individual streamer. This is measured in RMB and converted to USD.', list: []},
      {nameEN: 'Subscribers', nameCN: '游戏粉丝数', cate: 'sumFans', tips: 'For Games, this measures the daily average number of subscribers that are subscribed to streamers of a game or category during the selected time period. For streamers this measures the total number of subscribers for the streamers channel.',list: []},
      {nameEN: 'Heat Index', nameCN: '游戏热力值', cate: 'sumHot', tips: 'An index based on the daily average value of three streaming platforms’ own algorithms, providing a rating of the games number of viewers, hours streamed, and number of virtual gifts or tips donated.', list: []},
      {nameEN: 'Niko Index', nameCN: 'NIKO指数', cate: 'sumNiko', tips: 'A weighted index estimated by Niko based on an algorithm approximating the number of active viewers of each streamer, game or category. This is a cumulative figure for the period selected.', list: []}
    ],
  },
  effects: {
    *apiGetAnchorList({ payload }, { call, put }){
      const response = yield call(getCateList, payload)
      yield put({ type: 'setState', payload: response.datas })
    },
  },
  reducers: {
    setState(state, { payload }){
      let { cateList } = state
      if(payload){
        for (let index in payload){
          let old = _.find(cateList, { cate: index })
          if(old){
            old.list = payload[index]
          }
        }
      }
      return {
        ...state,
        cateList: _.cloneDeep(cateList)
      }
    }
  }
}
