import {
  getFaqList
} from '../services/faq'
import _ from 'lodash'

export default {
  namespace: 'faq',
  state: {
    faqList: null,
    page: 1,
    perPage: 50,
    lastPage: 1
  },
  effects: {
    *apiGetFaqList({ payload }, { call, put }){
      const response = yield call(getFaqList, payload)
      yield put({ type: 'setList', payload: response })
    },
    *apiGetNextFaqList({ payload }, { call, put }){
      const response = yield call(getFaqList, payload)
      yield put({ type: 'setNextList', payload: response })
    }
  },
  reducers: {
    setList(state, { payload }){
      return {
        ...state,
        faqList: payload,
        page: parseInt(payload.page),
        perPage: parseInt(payload.perPage),
        lastPage: parseInt(payload.lastPage)
      }
    },
    setNextList(state, { payload }){
      let { faqList } = state
      faqList.data = faqList.data.concat(payload.data)
      return {
        ...state,
        faqList: _.cloneDeep(faqList),
        page: parseInt(payload.page),
        perPage: parseInt(payload.perPage),
        lastPage: parseInt(payload.lastPage)
      }
    }
  }
}