import { requestGet } from '../utils/requestApi'

export async function getStreamerClubs(payload) {
  return requestGet('club', payload)
}

export async function getClubDetails(payload) {
  return requestGet('clubdetail', payload)
}
export async function getClubChart(payload) {
  return requestGet('clubChart', payload)
}