import { requestGet, requestPost, requestPut } from '../utils/requestApi'

export async function getUserInfo(payload){
  return requestGet('user/0', payload)
}

export async function putUserInfo(payload){
  return requestPut('user/0', payload)
}

export async function postCustomer(payload){
  return requestPost('customer', payload)
}

export async function getCollectionList(payload){
  return requestGet('star', payload)
}
export async function getStreamerList(payload){
  return requestGet('starStreamer', payload)
}

export async function postDownloadFiles(payload){
  return requestPost('download', payload)
}