import { getStreamerClubs, getClubDetails, getClubChart } from '../services/streamerClubs'
import { getStreamerList } from '../services/streamers'

export default {
  namespace: 'streamerClubs',
  state: {
    loading: false,
    clubList: null,
    page: 1,
    perPage: 20,
    clubDetails: null,
    spin: null,
    tableLoading: false,
    clubStreamers: null,
    clubChart: null
  },
  effects: {
    *apiGetStreamerClubs({payload},{call, put}){
      yield put({ type: 'setState', payload: {loading: true} })
      let response = yield call(getStreamerClubs, payload)
      yield put({ type: 'setState', payload: {loading: false, clubList: response, page: response.page, perPage: response.perPage} })
    },
    *apiGetClubDetails({payload},{call, put}){
      yield put({ type: 'setState', payload: { spin: true }})
      const response = yield call(getClubDetails, payload)
      yield put({ type: 'setState', payload: { spin: false, clubDetails: response.data }})
    },
    *apiGetClubStreamers({payload},{call, put}){
      yield put({ type: 'setState', payload: {tableLoading: true}})
      let response = yield call(getStreamerList, payload)
      yield put({type: 'setState', payload: {tableLoading: false, clubStreamers: response}})
    },
    *apiGetClubChat({payload},{call, put}){
      let response = yield call(getClubChart, payload)
      yield put({ type: 'setState', payload: {clubChart: response.data}})
    }
  },
  reducers: {
    setState(state, {payload}){
      return {
        ...state,
        ...payload
      }
    }
  }
}